import './filter.number.scss';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { InputNumber, Button } from 'antd';

const FilterNumber = ({
    selectedKeys = [], 
    setSelectedKeys,
    confirm, 
    clearFilters, 
    visible
}) => {
    const input1ref = React.useRef();
    const input2ref = React.useRef();
    
    const [value1, setValue1] = useState();
    const [value2, setValue2] = useState();
    
    useEffect(() => {
        if (input1ref.current) {
            setTimeout(() => {
                input1ref.current.select();
            });
        }
    }, [visible]);

    useEffect(() => {
        if (selectedKeys?.length === 3) {
            setValue1(selectedKeys[1]);
            setValue2(selectedKeys[2]);
        } else {
            setValue1();
            setValue2();
        }
    }, [selectedKeys]);

    const handleBlur = () => {
        input2ref.current.select();
    };

    const handleClearFilters = () => {
        setValue1();
        setValue2();
        clearFilters();
        confirm();
    };

    const handleSearch = () => {
        if (Object.isDefined(value1) && Object.isDefined(value2)) {
            setSelectedKeys(['BETWEEN', value1, value2]);
            confirm();
        } else {
            clearFilters();
        }
    };

    return (
        <div className="table-filter-dropdown-numbers">
            <InputNumber
                ref={input1ref}
                value={value1}
                onChange={val => setValue1(val)}
                onBlur={handleBlur}
                placeholder="From"
            />
            -
            <InputNumber
                ref={input2ref}
                value={value2}
                onChange={val => setValue2(val)}
                onPressEnter={handleSearch}
                placeholder="To"
            />
            <div className="lx-table-filter-dropdown-btns">
                <Button
                    type="link"
                    size="small"
                    onClick={handleClearFilters}
                    className="lx-table-filter-dropdown-link clear"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={handleSearch}
                    className="lx-table-filter-dropdown-link confirm"
                >
                    OK
                </Button>

            </div>
        </div>
    );
};

FilterNumber.propTypes = {
    selectedKeys: PropTypes.array,
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
};

export default FilterNumber;
