import React from 'react';
import { useSelector } from 'react-redux';

const IncidentSummaryEmployer = () => {
    const location = useSelector(state => state.location);
    const { fields } = useSelector(state => state.incident);
    return (
        <>
            <h2>Employer</h2>
            <div className="data-grid">
                <div>Search Code</div>
                <span>{location?.clientCode}</span>
                <div>Employer Location</div>
                <span>{fields?.employer_location_name?.value || location?.title}</span>
                <div>Assigned Phone</div>
                <span>{location?.assignedPhoneNumber}</span>
                <div>Address</div>
                <span>{fields?.employer_location_address?.value || location?.address1} </span>
                <div>City, State, Zip</div>
                <span>
                    {fields?.employer_location_city?.value || location?.city} 
                    {' '}
                    {fields?.employer_location_state?.value || location?.state} 
                    {' '}
                    {fields?.employer_location_zip?.value || location?.zip}
                </span>
            </div>
        </>
    );
};

export default IncidentSummaryEmployer;
