import './Spin.scss';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin as BaseSpin } from 'antd';

const antIcon = <LoadingOutlined spin />;

const Spin = props => <BaseSpin indicator={antIcon} {...props} />;

export { Spin, BaseSpin };
