import './Greeting.scss';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Form, Skeleton } from 'library';
import Context from '../context';

const CallerIdGreeting = () => {
    const { loading, topLocation } = useContext(Context);

    const call = useSelector(state => state.call);
    const location = useSelector(state => state.location);
    const locationGreetings = location?.specialGreeting || topLocation?.specialGreeting;

    return (
        <Form.Item label="Special Greetings">
            <Skeleton
                paragraph={{ rows: 3 }}
                title={false}
                active
                loading={loading}
                className="greetings-skeleton"
            >
                <div className="greetings">
                    <div>{locationGreetings}</div>
                    <div>
                        {call.isExistingIncident
                            ? 'I see you`re calling in regards to an existing incident. Is that correct?'
                            : 'I see you`re calling to report a new incident. Is that correct?'}
                    </div>
                </div>
            </Skeleton>

        </Form.Item>
    );
};

export default CallerIdGreeting;
