import api from '../api';

class EnterpriseCommonService {
    static search(uri, { id, query, page = 1, perPage = 100 }) {
        return api.get(uri, { id, page, perPage, query })
            .then(({ data }) => data);
    }
}

export default EnterpriseCommonService;
