import { formats } from 'utils';

export const getQueryParams = () => {
    const entries = new URLSearchParams(window.location.search).entries();
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
};

const transformSort = (sorter) => {
    if (Object.isEmpty(sorter)) return '';
    return {
        orderBy: sorter.field,
        orderDirection: (sorter.order === 'descend' ? 'desc' : 'asc')
    };
};

const transformFilters = (data, encode = false) => {
    const res = {};
    Object.keys(data)
        .forEach((key) => {
            const value = data[key];
            if (value && Array.isArray(value)) {
                const pattern = value[0];
                const values = value.slice(1, value.length);
                switch (pattern) {
                    case 'BETWEENDATE': {
                        res[key] = [
                            values[0].startOf('day').utc().format(formats.dateTime.out),
                            values[1].endOf('day').utc().format(formats.dateTime.out),
                        ];
                        break;
                    }
                    case 'BETWEENDATE_EXACT': {
                        res[key] = [
                            values[0].format(formats.date.out),
                            values[1].format(formats.date.out),
                        ];
                        break;
                    }
                    case 'BETWEEN': {
                        res[key] = [
                            values[0].toString(),
                            values[1].toString(),
                        ];
                        break;
                    }
                    case 'BOOL': {
                        if (values.length === 1) {
                            const [val] = values;
                            res[key] = val;
                        }
                        break;
                    }
                    case 'LIKE': {
                        const val = values.toString().toLowerCase();
                        res[key] = encode ? encodeURIComponent(val) : val;
                        break;
                    }
                    default: {
                        res[key] = value.map(val => (val === 'null' ? null : val));
                    }
                }
            } else if (value || value === 0) {
                res[key] = value;
            }
        });
    return res;
};
export const transformTableParams = (
    {
        page = 1,
        take = 500,
        filters = {},
        sort = {},
        columns = {},
        select = [],
        extend = [],
        ...rest
    }, encode
) => {
    const transformedFilters = transformFilters(filters, encode);
    const transformedSort = transformSort(sort);
    const data = {
        page,
        perPage: take,
        columns,
        select,
        extend,
        ...transformedSort,
        ...transformedFilters,
        ...rest

    };
    return data;
};
