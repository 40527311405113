const getShowIfDependencies = obj => Object.values(obj)
    .reduce((acc, item) => {
        let fields = [];
        if (item.fieldName) {
            fields = [item.fieldName];
        } else {
            fields = getShowIfDependencies(item);
        }
        return [...acc, ...fields];
    }, []);

const expFn = {
    // eslint-disable-next-line eqeqeq
    equals: (a, b) => a == b,
    notEquals: (a, b) => a !== b,
    less: (a, b) => a < b,
    greater: (a, b) => a > b,
    contains: (a, b) => (a || '').toString().includes(b),
    any: (a, b) => a || b,
    all: (a, b) => a && b
};

const calculateRow = ({ fieldName, operator, value }, getFieldValue, prefix = 'fields') => {
    const currentValue = getFieldValue([prefix, fieldName])?.value;
    const fn = expFn[operator];
    if (fn) {
        return fn(currentValue, value);
    }
    return true;
};

const calculateShowIf = (statement, getFieldValue) => {
    const operator = Object.keys(statement)[0];
    const expressions = Object.values(statement)[0];

    const visible = expressions
        .reduce((acc, expression) => {
            let res = true;
            if (expression.fieldName) {
                res = calculateRow(expression, getFieldValue);
            } else {
                res = calculateShowIf(expression, getFieldValue);
            }
            const expFnOp = expFn[operator];
            if (acc === undefined || !expFnOp) {
                return res;
            }
            return expFnOp(acc, res);
        }, undefined);
    return visible;
};

export {
    calculateShowIf,
    getShowIfDependencies
};
