import './Incidents.scss';
import React, { useState, useEffect } from 'react';
import { Table } from 'library';
import { EsIncidentsService } from 'services';
import { transformTableParams } from 'utils';
import ListConfig from './config';

const isSearchingForRef = (str) => {
    const x = str.charAt(0);
    if (x === '0') {
        return true;
    }
    return x.toLowerCase() !== x.toUpperCase();
};

const IncidentList = () => {
    const [size, setSize] = useState({});

    const setWindowDimensions = () => {
        setSize({
            height: window.innerHeight - 345,
            width: window.innerWidth - 610
        });
    };

    useEffect(() => {
        setWindowDimensions();
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const fetchAction = (args) => {
        const { refs, claimantFirstLastName, ...rest } = transformTableParams(args);
        if (refs && refs.length) {
            if (isSearchingForRef(refs)) {
                rest.claimReferenceNumber = {
                    value: refs,
                    pattern: 'STARTS_WITH'
                };
            } else if (refs.length > 8) {
                rest.claimReferenceNumber = {
                    value: refs,
                    pattern: 'LIKE'
                };
            } else {
                rest.refs = refs;
            }
        }
        if (claimantFirstLastName) {
            rest.claimantFirstLastNameFullText = claimantFirstLastName;
        }
        return EsIncidentsService.search(rest);
    };

    return (
        <div className="panel">
            <div className="panel-header">
                Incidents
            </div>
            <Table
                name="IncidentsTable"
                className="incidents-table"
                rowKey="id"
                columns={ListConfig}
                cache
                fetchAction={fetchAction}
                scroll={{ y: size.height }}
            />
        </div>
    );
};

export default IncidentList;
