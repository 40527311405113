import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'utils';

const NoteInfo = ({ note }) => {
    const createdAt = format(note.createdAt, 'MMM D, YYYY h:mm A');
    const createdBy = `${note.createdBy?.firstName} ${note.createdBy?.lastName.substr(0, 1)}.`;

    return (
        <div className="note-info">
            {createdBy}
            {' '}
            <span>{createdAt}</span>
        </div>
    );
};

NoteInfo.propTypes = {
    note: PropTypes.object.isRequired,
};

export default NoteInfo;
