import { useCallback, useState } from 'react';

import { debounce } from 'utils';

export const useDebounce = (obj = null, wait = 1000) => {
    const [state, setState] = useState(obj);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceThis = useCallback(
        debounce((_prop) => {
            setState(_prop);
        }, wait),
        []);

    const setDebouncedState = (_val) => {
        debounceThis(_val);
    };

    return [state, setDebouncedState];
};
