import { GENESYS } from 'config';
import dayjs from 'dayjs';
import { GenesysApp } from 'services/genesysApp';
import { usersApi, apiClient } from './utils';

const { clientId, environment, appName } = GENESYS;
const callbackPath = '/';
const callbackUri = `https://${window.location.host}${callbackPath}`;

apiClient.setPersistSettings(true, appName);
apiClient.setEnvironment(environment);

class AuthService {
    me;

    static #setupRefreshToken({ tokenExpiryTime }) {
        const expDate = dayjs(tokenExpiryTime);
        const expiresInSeconds = expDate.diff(dayjs(), 'second') - 2;
        setTimeout(() => {
            AuthService.signIn();
        }, expiresInSeconds * 1000);
    }

    static signIn() {
        const state = window.location.pathname;
        return apiClient.loginImplicitGrant(clientId, callbackUri, { state })
            .then((data) => {
                const forbiddenStates = [window.location.pathname, callbackPath];

                if (data.state && !forbiddenStates.includes(data.state)) {
                    document.location.replace(data.state);
                }

                return AuthService.#setupRefreshToken(data);
            })
            .then(() => usersApi.getUsersMe({ expand: ['presence'] }))
            .then(me => this.me = me)
            .then((data) => {
                GenesysApp.alert('Connected successfully! 😊');
                return data;
            });
    }

    static getAccessToken() {
        return apiClient?.authData.accessToken;
    }

    static deactivateToken() {
        apiClient.authData = { ...apiClient.authData, accessToken: 'unactive' };
    }

    static isSignedIn() {
        return !!AuthService.getAccessToken();
    }
}

export default AuthService;
