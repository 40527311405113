/* eslint-disable max-len */
import { formatSsn, formatDate, } from 'utils';
import { USStateOptions } from 'config';

export default {
    source: 'GB API',
    sourceType: 'contact',
    title: 'Gallagher Bassett',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    transform: data => data.sortByString('firstName'),
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            width: 120,
        },
        {
            label: 'SSN',
            source: 'ssn',
            target: 'claimant_ssn',
            filterType: 'ssn',
            filter: 'ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            source: 'dob',
            target: 'claimant_birth_date',
            filter: 'dob',
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            target: 'claimant_employee_id',
            source: 'employeeId',
            filter: 'employeeId',
            width: 100
        },
        {
            label: 'Email',
            source: 'email',
            target: 'claimant_email_address',
            width: 120
        },
        {
            label: 'Home Phone',
            source: 'homePhone',
            target: 'claimant_mobile_phone',
            width: 150
        },
        {
            label: 'Work Phone',
            source: 'workPhone',
            target: 'claimant_work_phone',
            width: 150
        },
        {
            label: 'Gender',
            source: 'gender',
            target: 'claimant_gender_code',
            render: value => ({ 1: 'M', 2: 'F' }[value] || ''),
            targetFn: value => (value || '').toString(),
            width: 60
        },
        {
            label: 'Address',
            source: 'address1',
            target: 'claimant_address',
            width: 120
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 100
        },
        {
            label: 'State',
            target: 'claimant_state',
            source: 'state',
            options: USStateOptions,
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
        {
            label: 'Location',
            source: 'location_name',
            target: 'employer_location_name',
            width: 150,
        },
        {
            label: 'Location Ref. Number',
            source: 'location_referenceNumber',
            target: 'employer_location_number',
            width: 70,
        },
        {
            label: 'Location Address',
            source: 'location_address1',
            target: 'employer_location_address',
            width: 150,
        },
        {
            label: 'Location Address 2',
            source: 'location_address2',
            target: 'employer_location_address2',
        },
        {
            label: 'Location City',
            source: 'location_city',
            target: 'employer_location_city',
            width: 120,
        },
        {
            label: 'Location State',
            source: 'location_state',
            render: value => value.substr(0, 2),
            target: 'employer_location_state',
            targetFn: value => value.substr(0, 2),
        },
        {
            label: 'Location Zip',
            source: 'location_postalCode',
            target: 'employer_location_zip',
        },
    ]
};
