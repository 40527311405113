/* eslint-disable react/no-unstable-nested-components */
import './LibraryDropdown.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Popover } from 'library';
import { SearchOutlined } from '@ant-design/icons';

const NoteLibraryDropdown = ({
    items = [],
    children,
    onSelect,
    trigger = 'contextMenu'
}) => {
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (items?.length) {
            setOptions(items);
        }
    }, [items]);

    const onFilter = (val) => {
        const value = val.toLowerCase();
        const newOptions = items
            .filter(item => (
                item.tag?.toLowerCase().includes(value)
                || item?.text.toLowerCase().includes(value)
            ));
        setOptions(newOptions);
    };

    const onClick = (item) => {
        setOpen(false);
        onSelect(item);
    };

    return (
        <Popover
            menu={{ items: options }}
            overlayClassName="notes-library-dropdown"
            trigger={[trigger]}
            open={open}
            onOpenChange={setOpen}
            content={(
                <>
                    <Input
                        suffix={<SearchOutlined />}
                        onChange={e => onFilter(e.target.value)}
                        allowClear
                    />
                    <ul>
                        {options.map((item, index) => (
                            // eslint-disable-next-line max-len
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus, jsx-a11y/no-noninteractive-element-interactions
                            <li
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                onClick={() => onClick(item.text)}
                            >
                                {item.text}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        >
            {children}
        </Popover>
    );
};

NoteLibraryDropdown.propTypes = {
    items: PropTypes.array,
    children: PropTypes.any.isRequired,
    onSelect: PropTypes.func.isRequired,
    trigger: PropTypes.string
};

export default NoteLibraryDropdown;
