import './Empty.scss';
import PropTypes from 'prop-types';
import { Empty as EmptyBase } from 'antd';
import { EmptyImage } from 'assets';

const Empty = ({ image = EmptyImage, ...rest }) => (
    <EmptyBase
        image={image}
        {...rest}
    />            
);

Empty.propTypes = {
    image: PropTypes.string
};

export { Empty };
