import { Button } from 'library';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const ButtonBack = () => {
    const navigate = useNavigate();

    return (
        <Button
            type="default"
            className="button-back"
            onClick={() => navigate(-1)}
            icon={<ArrowLeftOutlined />}
        />
    );
};

export { ButtonBack };
