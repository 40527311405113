import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { EsCommonService } from 'services';
import { AutoComplete } from './AutoComplete';

/*  Used for dynamic form only - 
    employment_job_title_or_occupation, 
    injury_location_department_desc 
    fetch: {
        api: string,
        populate: [fieldNames]
    }
*/

const AutoCompleteES = ({
    fetch: { api, populate },
    onChange,
    ...rest
}) => {
    const form = Form.useFormInstance();
    
    const fetchOptions = (...args) => EsCommonService.search(api, ...args)
        .then(({ data: items, pagination }) => ({
            total: pagination?.totalResults || items.length,
            page: pagination?.currentPage || 1,
            options: items.map(item => ({ value: item.title }))
        }));
    
    const onBeforeChange = (val) => {
        onChange(val);
        if (form && populate?.length) {
            populate.forEach((it) => {
                form.setFieldValue(it, { value: val });
            });
        }
    };
        
    return (
        <AutoComplete
            fetchOptions={fetchOptions}
            populate={populate}
            minLength={3}
            onChange={onBeforeChange}
            {...rest}
        />
    );
};

AutoCompleteES.propTypes = {
    fetch: PropTypes.shape({
        api: PropTypes.string.isRequired,
        populate: PropTypes.array,
    }).isRequired,
    onChange: PropTypes.func
};

export { AutoCompleteES };
