/* eslint-disable max-len */
import { formatSsn, formatDateOut, } from 'utils';

export default {
    source: 'TriStar',
    sourceType: 'contact',
    title: 'TriStar Contacts',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            required: true,
            width: 120,
        },
        {
            label: 'Middle Name',
            target: 'claimant_middle_initial',
            source: 'middleName',
            width: 120,
        },
        {
            label: 'SSN',
            source: 'ssn',
            target: 'claimant_ssn',
            filterType: 'ssn',
            render: formatSsn,
            filter: 'ssn',
            width: 120
        },
        {
            label: 'Date of Birth',
            source: 'dob',
            target: 'claimant_birth_date',
            targetFn: formatDateOut,
            filter: 'dob',
            width: 90
        },
        {
            label: 'Employee Id',
            target: 'claimant_employee_id',
            source: 'employeeId',
            filter: 'employeeId',
            width: 80
        },
        {
            label: 'Hire Date',
            source: 'hireDate',
            targetFn: formatDateOut,
            target: 'employment_hired_date',
            filter: 'hireDate',
            width: 70
        },
        {
            label: 'Gender',
            source: 'gender',
            target: 'claimant_gender_code',
            width: 70,
            targetFn: value => (
                {
                    M: '1',
                    F: '2'
                }[value] || undefined),
        },
        {
            label: 'Address',
            source: 'address1',
            target: 'claimant_address',
            width: 220
        },
        {
            label: 'Address 2',
            source: 'address2',
            target: 'claimant_address2',
            width: 220
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 100
        },
        {
            label: 'State',
            target: 'claimant_state',
            source: 'state',
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
    ]
};
