import { USStateOptions } from 'config';

export default {
    source: 'GB Pyramid',
    sourceType: 'location',
    title: 'GB Location',
    className: 'info',
    columns: [
        {
            label: 'Title',
            source: 'name',
            filter: 'name',
            target: 'employer_location_name',
            width: 200,
        },
        {
            label: 'Address',
            source: 'address',
            filter: 'address',
            target: 'employer_location_address',
            width: 200,
        },
        {
            label: 'City',
            source: 'city',
            filter: 'city',
            target: 'employer_location_city',
            width: 150,
        },
        {
            label: 'State',
            source: 'stateAbbr',
            filter: 'state',
            target: 'employer_location_state',
            options: USStateOptions,
            width: 100,
        },
        {
            label: 'Zip',
            source: 'zip',
            filter: 'postalCode',
            target: 'employer_location_zip',
            width: 80,
        },
        {
            label: 'Reference',
            source: 'referenceNumber',
            filter: 'referenceNumber',
            target: 'employer_location_number',
            width: 80,
        }
    ]
};
