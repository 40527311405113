import axios from 'axios';

const GoogleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const cityTypes = ['locality', 'sublocality_level_1', 'neighborhood'];
const stateTypes = ['administrative_area_level_1'];
const zipTypes = ['postal_code'];

const geocodeApi = axios.create({
    baseURL: 'https://maps.googleapis.com/maps/api/geocode',
    timeout: 600011,
});

const getProp = (address, types) => address.find(item => types.includes(item.types[0]))?.short_name;

class GeocodeService {
    static search(filters) {
        const params = {
            key: GoogleApiKey,
            language: 'en',
            region: 'us',
            ...filters,

        };
        return geocodeApi.get('/json', { params })
            .then(({ data: { results } }) => {
                if (!results.length) {
                    return [];
                }
                const {
                    address_components: address,
                    place_id: placeId,
                    postcode_localities: cities
                } = results[0];

                const state = getProp(address, stateTypes);
                const city = getProp(address, cityTypes);
                const zip = getProp(address, zipTypes);
                if (!city || !state) {
                    return [];
                }

                if (cities?.length) {
                    return cities.map((name, index) => ({
                        id: `${placeId}_${index}`,
                        city: name,
                        state,
                        zip,
                    }));
                }

                return [{
                    id: placeId,
                    city,
                    state,
                    zip
                }];
            });
    }

    static searchByZip(zip) {
        const params = {
            components: `postal_code:${zip}|country:US`
        };
        return GeocodeService.search(params);
    }
}

export { GeocodeService };
