import { useState } from 'react';
import { notification, Input } from 'library';
import { GnConversationService } from 'services';

const GenesysIncident = () => {
    const [loading, setLoading] = useState(false);
    const [conversation, setConversation] = useState();

    const getIncident = (id) => {
        setLoading(true);
        GnConversationService.get(id)
            .then(setConversation)
            .catch((e) => {
                notification.error({ message: e.message });
            })
            .finally(() => setLoading());
    };
    return (
        <>
            <Input.Search
                defaultValue="f0eddd30-bbe2-4a0a-9ca3-bb0cc1c2ea29"
                onSearch={getIncident}
                loading={loading}
                enterButton
            />
            <pre>{JSON.stringify(conversation, null, 2)}</pre>
        </>
    );
};

export default GenesysIncident;
