/* eslint-disable max-len */
import { formatDateOut, } from 'utils';

export default {
    source: 'SC Tray',
    sourceType: 'contact',
    title: 'SC Tray Contacts',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            required: true,
            width: 120,
        },
        {
            label: 'Date of Birth',
            source: 'dateOfBirth',
            target: 'claimant_birth_date',
            filter: 'dateOfBirth',
            targetFn: formatDateOut,
            width: 90
        },
        {
            label: 'Employee Id',
            target: 'claimant_employee_id',
            source: 'employeeId',
            filter: 'employeeId',
            width: 80
        },
        {
            label: 'Occupation',
            source: 'occupation',
            target: 'employment_job_title_or_occupation',
            width: 120
        },
        {
            label: 'Hire Date',
            source: 'dateOfHire',
            target: 'employment_hired_date',
            targetFn: formatDateOut,
            filter: 'hireDate',
            width: 70
        },
        {
            label: 'Email',
            source: 'email',
            target: 'claimant_email_address',
        },
        {
            label: 'Home Phone',
            source: 'homePhone',
            target: 'claimant_home_phone',
            width: 150
        },
        {
            label: 'Gender',
            source: 'gender',
            target: 'claimant_gender_code',
            width: 70,
            targetFn: value => (
                {
                    M: '1',
                    F: '2'
                }[value] || undefined),
        },
        {
            label: 'Marital Status',
            source: 'maritalStatus',
            target: 'claimant_marital_status_code',
            targetFn: value => (
                {
                    Single: '2',
                    Married: '4',
                    Divorced: '3',
                    Separated: '5',
                    Widowed: '6',
                    Unknown: '7',
                    'Domestic Partner': '8',
                }[value] || undefined),
            width: 80
        },
        {
            label: 'Address',
            source: 'address1',
            target: 'claimant_address',
            width: 220
        },
        {
            label: 'Address 2',
            source: 'address2',
            target: 'claimant_address2',
            width: 220
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 100
        },
        {
            label: 'State',
            target: 'claimant_state',
            source: 'state',
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
    ]
};
