import { useState } from 'react';
import PropTypes from 'prop-types';
import { EsDistributionsService } from 'services';
import { notification, Modal, Input, InputPhone, Form } from 'library';
import { DISTRIBUTION_SEND } from 'config/distribution';
import { Rules } from 'utils';

const configs = {
    [DISTRIBUTION_SEND.SMS]: {
        label: 'Use Phone Number',
        component: <InputPhone />
    },
    [DISTRIBUTION_SEND.FAX]: {
        label: 'Use Phone Number',
        component: <InputPhone />
    },
    [DISTRIBUTION_SEND.EMAIL]: {
        label: 'Use Email',
        component: <Input />
    },
};

const RequeueDistributionModal = ({ setDistribution, distribution, onSuccess }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = ({ sendTo }) => {
        setLoading(true);
        EsDistributionsService.requeue(distribution.id, { sendTo })
            .then(() => {
                notification.success({ message: 'Sent successfully' });
                onSuccess();
                setDistribution();
            })
            .finally(() => setLoading(false));
    };

    const sendVia = distribution.sendVia?.toUpperCase() || '';
    const field = configs[sendVia.toLowerCase()] || null;

    const title = <>Re-Send {sendVia} Distribution</>;
    return (
        <Modal
            title={title}
            className="info modal-triage"
            onOk={form.submit}
            onCancel={() => setDistribution()}
            okButtonProps={{ loading }}
            okText="Confirm"
            open={!!distribution}
            centered
        >
            <div className="content distribution-confirm">
                <div>
                    Are you sure you want to re-send
                    {' '}
                    {sendVia}
                    {' '}
                    Distribution
                    {' '}
                    <b>{distribution.id}</b>?
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    hideRequiredMark
                    onFinish={onFinish}
                    initialValues={distribution}
                >
                    {field && (
                        <>
                            Please confirm the requeueing information below is correct:
                            <Form.Item
                                name="sendTo"
                                rules={[Rules.required]}
                                label={field.label || ''}
                            >
                                {field.component}
                            </Form.Item>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
};

RequeueDistributionModal.propTypes = {
    distribution: PropTypes.object.isRequired,
    setDistribution: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default RequeueDistributionModal;
