import { INCIDENT_TYPE_WC } from 'config';
import api from '../api';
import transformGet from './transform.get';
// import DIRECTIVES_ALL_TYPES from './mock';

class EnterpriseDirectivesService {
    static async list({ locationId, episodeTypeId = INCIDENT_TYPE_WC }) {
        const params = {
            episodeTypeId,
            // flow: flow || INCIDENT_STATE.INTAKE,
        };
        const { data } = await api.get(`/api/v2/locations/${locationId}/directives`, params);
        return transformGet(data);
    }
}

export default EnterpriseDirectivesService;
