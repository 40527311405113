import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, TextArea } from 'library';
import { EsIncidentsService } from 'services';
import { useSelector } from 'react-redux';

const DismissButton = ({ incidents, setOpen }) => {
    const incident = useSelector(state => state.incident);
    const [updating, setUpdating] = useState(false);
    const [dismissReason, setDismissReason] = useState();

    const onDismissClick = () => {
        if (!incident.id) {
            setOpen(false);
            return;
        }
        setUpdating(true);
        const ids = incidents.map(it => it.id).join(',');
        EsIncidentsService.update(incident.id, {
            matches: {
                memo: `Incidents: [${ids}], ${dismissReason ? ` Reason: ${dismissReason}` : ''}`,
                value: 'Dismiss'
            }
        })
            .finally(() => {
                setUpdating(false);
                setOpen(false);
            });
    };
    const hasFullMatch = incidents.find(item => !!item.$fullMatch);

    return (
        <Form layout="vertical">
            {incident.id && hasFullMatch && (
                <Form.Item
                    label="Reason to Dismiss"
                    className="required"
                >
                    <TextArea
                        value={dismissReason}
                        onChange={setDismissReason}
                        placeholder="Required"
                    />
                </Form.Item>
            )}
            <Button
                type="primary"
                block
                onClick={onDismissClick}
                disabled={incident.id && hasFullMatch && !dismissReason}
                loading={updating}
            >
                Dismiss
            </Button>
        </Form>
    );
};

DismissButton.propTypes = {
    incidents: PropTypes.array.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default DismissButton;
