/* eslint-disable max-len */
import { USStateOptions } from 'config';
import { formatSsn, formatDate } from 'utils';

export default {
    source: 'UKG',
    sourceType: 'contact',
    title: 'UKG',
    transform: data => data.sortByString('firstName'),
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            width: 80
        },
        {
            label: 'SSN',
            source: 'ssn',
            target: 'claimant_ssn',
            filterType: 'ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            source: 'dateOfBirth',
            target: 'claimant_birth_date',
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            target: 'claimant_employee_id',
            source: 'employeeId',
            filter: 'employeeId',
            width: 100
        },
        {
            label: 'Email',
            source: 'emailAddress',
            target: 'claimant_email_address',
            width: 120
        },
        {
            label: 'Home Phone',
            source: 'homePhone',
            target: 'claimant_mobile_phone',
            width: 150
        },
        {
            label: 'Gender',
            source: 'gender',
            target: 'claimant_gender_code',
            targetFn: value => (
                {
                    M: '1',
                    F: '2'
                }[value] || undefined),
            width: 60
        },
        {
            label: 'Address',
            source: 'homeAddress',
            target: 'claimant_address',
            width: 120
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 100
        },
        {
            label: 'State',
            target: 'claimant_state',
            source: 'state',
            filter: 'addressState',
            options: USStateOptions,
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
    ]
};
