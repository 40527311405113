import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button } from 'library';

const FilterBoolean = (props) => {
    const [value, setValue] = useState(false);
    const {
        selectedKeys = [], 
        setSelectedKeys,
        confirm, filters, clearFilters
    } = props;

    useEffect(() => {
        setValue(selectedKeys);
    }, [selectedKeys]);

    const handleSearch = () => {
        if (value) {
            setSelectedKeys(['BOOL', ...value]);
            confirm();
        } else {
            clearFilters();
        }
    };

    const handleClearFilters = () => {
        setValue();
        clearFilters();
        confirm();
    };

    return (
        <div>
            <Checkbox.Group
                onChange={setValue}
                value={value}
                className="checkbox-group-vertical"
            >
                {filters.map(i => (
                    <Checkbox
                        key={i.value}
                        value={i.value}
                    >
                        {i.label}
                    </Checkbox>
                ))}
            </Checkbox.Group>
            <div className="lx-table-filter-dropdown-btns">
                <Button
                    type="link"
                    size="small"
                    onClick={handleClearFilters}
                    className="lx-table-filter-dropdown-link clear"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={handleSearch}
                    className="lx-table-filter-dropdown-link confirm"
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

FilterBoolean.propTypes = {
    selectedKeys: PropTypes.array, 
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired, 
    filters: PropTypes.array.isRequired, 
    clearFilters: PropTypes.func.isRequired,
};

export default FilterBoolean;
