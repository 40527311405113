/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import './filters.scss';
import React from 'react';
import { FilterFilled } from '@ant-design/icons';
import FilterDate from './filter.date';
import FilterText from './filter.text';
import FilterNumber from './filter.number';
import FilterCheckboxes from './filter.checkboxes';
import { FilterSelect } from './filter.select';
import { ColumnTypes } from '../config';
import FilterBoolean from './filter.bool';

const FilterIcon = filtered => (
    <FilterFilled
        className={filtered && 'filtered'}
    />
);

const FilterdYesNo = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 }
];

const dateTypes = [
    ColumnTypes.date, ColumnTypes.dateTime,
    ColumnTypes.dateExact, ColumnTypes.dateTimeExact
];

const getFiltersProps = (item, filterRef) => {
    const { filter } = item;

    let filterProps = {};

    if (!filter) {
        return filterProps;
    }

    if (item.type === ColumnTypes.bool) {
        filterProps = {
            filters: Array.isArray(filter) ? filter : FilterdYesNo,
            filterIcon: FilterIcon,
            filterDropdown: props => (
                <FilterBoolean
                    {...props}
                    label={item.title}
                    getPopupContainer={trigger => trigger.parentNode}
                />
            ),
        };
    } else if (Array.isArray(filter) && item.isFilterSingleSelect) {
        filterProps = {
            filters: filter,
            filterMultiple: true,
            filterIcon: FilterIcon,
            filterDropdown: props => (
                <FilterSelect
                    {...props}
                    label={item.title}
                />
            ),
        };
    } else if (Array.isArray(filter)) {
        filterProps = {
            filters: filter,
            filterMultiple: true,
            filterIcon: FilterIcon,
            filterDropdown: props => (
                <FilterCheckboxes
                    {...props}
                    label={item.title}
                />
            ),
        };
    } else if (Object.isFunction(filter)) {
        filterProps = {
            filterIcon: FilterIcon,
            filterDropdown: filter
        };
    } else if (dateTypes.includes(item.type)) {
        const pattern = [ColumnTypes.date, ColumnTypes.dateTime].includes(item.type)
            ? 'BETWEENDATE' : 'BETWEENDATE_EXACT';
        filterProps = {
            filterIcon: FilterIcon,
            filterDropdown: props => (
                <FilterDate
                    {...props}
                    label={item.title}
                    pattern={pattern}
                    getPopupContainer={trigger => trigger.parentNode}
                />
            ),
        };
    } else if (item.type === ColumnTypes.number) {
        filterProps = {
            filterIcon: FilterIcon,
            filterDropdown: props => (
                <FilterNumber
                    {...props}
                    label={item.title}
                />
            ),
        };
    } else {
        filterProps = {
            filterIcon: FilterIcon,
            filterDropdown: props => (
                <FilterText
                    {...props}
                    label={item.title}
                    ref={filterRef}
                />
            ),
            onFilterDropdownOpenChange: (open) => {
                if (open) {
                    setTimeout(() => {
                        filterRef.current.select();
                    });
                }
            }
        };
    }
    return filterProps;
};

export { getFiltersProps };
