import './test.scss';
import { useState } from 'react';
import { Input, Space, Button, Select, Switch } from 'library';
import { PhoneOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { transformCall, clear as clearCall } from 'store/call';
import { clear as clearLocation } from 'store/location';
import { isProd, INCIDENT_TYPES, INCIDENT_TYPE_ATTRIBUTE } from 'config';

import conversationJSON from './conversationJSON';

const conversationBase = { ...conversationJSON };

const inIFrame = (window.location !== window.parent.location);

const parsePhone = v => `+${parseFloat(v.replace(/[^\d]/g, ''))}`;

const Test = () => {
    const dispatch = useDispatch();
    const [isJson, setIsJson] = useState(false);
    const [json, setJson] = useState();
    const [from, setFrom] = useState('+18014443844');
    const [to, setTo] = useState('+19452533887'); // '+18332510417'
    const [type, setType] = useState('WC');
    const call = useSelector(state => state.call);

    const onPhoneClick = () => {
        if (!call.isConnected) {
            let conversation;
            if (isJson && json) { 
                dispatch(transformCall(JSON.parse(json)));
            } else {
                conversation = { ...conversationBase };
                conversation.fromPhone = parsePhone(from);
                conversation.toPhone = parsePhone(to);
                let customer = conversation.participants.find(item => item.purpose === 'customer');
                customer = {
                    ...customer,
                    attributes: { 
                        ...customer.attributes,
                        [INCIDENT_TYPE_ATTRIBUTE]: type,
                    } 
                };
                const agent = { ...conversation.participants.find(item => item.purpose === 'agent') };
                
                const participants = conversation.participants.filter(item => !['customer', 'agent'].includes(item.purpose));
                conversation.participants = [customer, agent, ...participants];
                dispatch(transformCall({ ...conversation }));
            }
        } else {
            dispatch(clearCall());
            dispatch(clearLocation());
        }
    };

    if (inIFrame || isProd) {
        return null;
    }

    return (
        <div className="mocked_call">
            <Switch
                value={isJson}
                onChange={setIsJson}
                checkedChildren="JSON"
                unCheckedChildren="Phones"
                style={{ marginRight: 30 }}
            />

            <Space.Compact className={call.status}>
                {isJson ? (
                    <Input
                        style={{ width: 330 }}
                        value={json}
                        onChange={e => setJson(e.target.value)}
                    />
                ) : (
                    <>
                        <Input
                            style={{ width: 110 }}
                            value={from}
                            onChange={e => setFrom(e.target.value)}
                        />

                        <Select
                            options={INCIDENT_TYPES.map(item => ({
                                value: item.genesysProps.length ? item.genesysProps[0] : item.abbr, 
                                label: item.abbr
                            }))}
                            style={{ width: 80 }}
                            value={type}
                            onChange={setType}
                        />
                        <Button
                            className={call.status}
                            icon={<RightOutlined />}
                            disabled
                        />
                        <Input
                            style={{ width: 110 }}
                            value={to}
                            onChange={e => setTo(e.target.value)}
                        />
                    </>
                )}
                <Button
                    type="primary"
                    icon={<PhoneOutlined />}
                    onClick={onPhoneClick}
                />
            </Space.Compact>
        </div>
    );
};

export default Test;
