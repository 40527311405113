import { formats } from 'utils';
import dayjs from 'dayjs';

const transform = (list) => {
    if (list && !list.data) {
        return list;
    }
    return {
        data: list.data?.map((item) => {
            const file = item.files?.length ? item.files[0] : {};
            const createdDate = dayjs(item.createdAt).format(formats.dateTime.in); 

            return {
                ...item,
                hasFile: file?.isFile,
                createdDate,
                reportTitle: file.reportTitle || '',
            };
        })
    };
};

export default transform;
