const transformContacts = ({ data }) => {
    let res = [];
    Object.keys(data).forEach((source) => {
        const sourceData = data[source];
        if (Array.isArray(sourceData)) {
            res = [...res, ...sourceData];
        }
    });
    return res;
};
export default transformContacts;
