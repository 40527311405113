/* eslint-disable max-len */
import { formatDate, formatDateOut } from 'utils';

export default {
    source: 'Safelite',
    sourceType: 'contact',
    title: 'Safelite',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            width: 120
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            required: true,
            width: 100,
        },
        {
            label: 'SSN',
            target: 'claimant_ssn',
            source: 'ssn',
            filterType: 'ssn',
            width: 120
        },
        {
            label: 'Date of Birth',
            source: 'dob',
            target: 'claimant_birth_date',
            render: formatDate,
            targetFn: formatDateOut,
            width: 100
        },
        {
            label: 'Employee Id',
            source: 'employeeId',
            target: 'claimant_employee_id',
            width: 150
        },
        {
            label: 'Occupation',
            source: 'jobTitle',
            target: 'employment_job_title_or_occupation',
            width: 100
        },
        {
            label: 'Hire Date',
            source: 'hireDate',
            target: 'employment_hired_date',
            render: formatDate,
            targetFn: formatDateOut,
            width: 90
        },
        {
            label: 'Email',
            target: 'claimant_email_address',
            source: 'email',
            width: 100
        },
        {
            label: 'Home Phone',
            source: 'homePhone',
            target: 'claimant_mobile_phone',
            width: 90
        },
        {
            label: 'Marital Status',
            source: 'maritalStatus',
            target: 'claimant_marital_status_code',
            targetFn: value => (
                {
                    'Single (United States of America)': '2',
                    'Married (United States of America)': '4',
                    'Divorced (United States of America)': '3',
                    'Separated (United States of America)': '5',
                    'Not Indicated (United States of America)': '7',
                    'Civil Partnership (United States of America)': '8',
                }[value] || undefined),
            width: 100
        },
        {
            label: 'Address',
            source: 'homeAddress',
            target: 'claimant_address',
            width: 120
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 90
        },
        {
            label: 'State',
            source: 'state',
            target: 'claimant_state',
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
        {
            label: 'Supervisor First Name',
            source: 'supervisorFirstName',
            target: 'employment_supervisor_first_name',
            width: 100
        },
        {
            label: 'Supervisor Last Name',
            source: 'supervisorLastName',
            target: 'employment_supervisor_last_name',
            width: 100
        },
        {
            label: 'Supervisor Phone Number',
            source: 'supervisorPhone',
            target: 'employment_supervisor_phone',
            width: 100
        },
    ]
};

/*
    [
 {
            "firstName": "John",
            "lastName": "Griffin",
            "email": "",
            "ssn": "7430",
            "dob": "1982-05-09",
            "employeeId": "00006715",
            "hireDate": "07\/01\/2008",
            "homeAddress": "23 Tener St",
            "homePhone": "",
            "maritalStatus": "Single (United States of America)",
            "jobTitle": "W8203 - Warehouse Associate I",
            "supervisorFirstName": "Daniel",
            "supervisorLastName": "Malcomb",
            "supervisorPhone": "",
            "city": "Luzerne",
            "state": "PA",
            "zip": "18709",
            "primaryOccupation": null
        }
    ]
*/
