import { useSelector } from 'react-redux';
import { Form } from 'library';
import { useDeepCompareEffect, useDebounce } from 'hooks';
import IncidentDuplicates from './Duplicates';

const IncidentDuplicatesCallerId = (props) => {
    const { toPhone } = useSelector(state => state.call);
    const location = useSelector(state => state.location);
    const form = Form.useFormInstance();
    const locationId = Form.useWatch('locationId', form);
    const incidentTypeId = Form.useWatch('episodeTypeId', form);
    const assignedPhoneNumber = toPhone || location.assignedPhoneNumber;
    const [debouncedFilters, setDebouncedFilters] = useDebounce(null, 500);

    const claimantFirstLastName = Form.useWatch(({ callerFirstName, callerLastName }) => {
        if (!callerFirstName || !callerLastName) {
            return undefined;
        }
        return `${callerFirstName} ${callerLastName}`;
    }, form);
   
    const propsToCheck = {
        locationId,
        incidentTypeId, 
        claimantFirstLastName, 
        assignedPhoneNumber
    };
    
    useDeepCompareEffect(() => {
        if (Object.hasAllProps(propsToCheck)) {
            setDebouncedFilters({
                locationMatch: {
                    assignedPhoneNumber,
                    locationId,
                },
                claimantMatch: {
                    claimantFirstLastName,
                },
                incidentTypeId
            });
        } else {
            setDebouncedFilters();
        }
    }, [propsToCheck]);

    return (
        <IncidentDuplicates
            filters={debouncedFilters}
            filterValues={propsToCheck}
            incidentTypeId={incidentTypeId}
            showLoading
            {...props}
        />
    );
};

const IncidentDuplicatesIntake = () => {
    const [debouncedFilters, setDebouncedFilters] = useDebounce(null, 1500);
    const { 
        id: incidentId, locationId, 
        fields, 
        incidentTypeId 
    } = useSelector(state => state.incident);
    const { assignedPhoneNumber } = useSelector(state => state.location);
    const fieldDOB = fields?.claimant_birth_date;
    const fieldSSN = fields?.claimant_ssn;
    const firstName = fields?.claimant_first_name?.value || '';
    const lastName = fields?.claimant_last_name?.value || '';
    const claimantFirstLastName = firstName && lastName ? `${firstName} ${lastName}` : undefined;
    const claimantBirthDate = fieldDOB?.value;
    const claimantSsn = fieldSSN?.value;
    
    const propsCheck = {
        locationId, 
        incidentTypeId,
        assignedPhoneNumber,
        claimantFirstLastName, 
        claimantSsn: (fieldSSN?.unknown || fieldSSN?.refused) ? 'full' : fieldSSN?.value,
        claimantBirthDate: (fieldDOB?.unknown || fieldDOB?.refused) ? 'full' : fieldDOB?.value, 
    };

    useDeepCompareEffect(() => {
        if (Object.hasAllProps(propsCheck)) {
            setDebouncedFilters({
                locationMatch: {
                    assignedPhoneNumber, 
                    locationId,
                },
                claimantMatch: {
                    claimantFirstLastName,
                    claimantSsn,
                    claimantBirthDate,
                },
                id: {
                    value: incidentId,
                    pattern: '<', 
                },
                incidentTypeId
            });
        } else {
            setDebouncedFilters();
        }
    }, [propsCheck]);

    return (
        <IncidentDuplicates
            filters={debouncedFilters}
            filterValues={{
                locationId, 
                incidentTypeId,
                assignedPhoneNumber,
                claimantFirstLastName, 
                claimantSsn,
                claimantBirthDate
            }}
            incidentTypeId={incidentTypeId}
        />
    );
};

export { IncidentDuplicatesCallerId, IncidentDuplicatesIntake };
