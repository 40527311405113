import dayjs from 'dayjs';
import { getIncidentType, INCIDENT_STATE } from 'config';

const EmptyEnspiriDates = ['0000-00-00', '0000-00-00 00:00:00', '', null];

const dateValue = val => (EmptyEnspiriDates.includes(val) ? undefined : dayjs(val));

const transformItem = (data) => {
    const { claimDetails, ...item } = data;
    const injuryDate = dateValue(item.injuryDate);
    const createdDate = dateValue(item.createdDate);
    const updateDateTime = dateValue(item.updateDateTime);
    const employerNotifiedDateTime = dateValue(item.employerNotifiedDateTime);
    const $incidentType = getIncidentType(item.episodeTypeId);
    const details = claimDetails || {};

    return {
        ...item,
        ...details,
        injuryDate,
        injuryDateString: item.injuryDate,
        createdDate,
        updateDateTime,
        employerNotifiedDateTime,
        $incidentType,
        $isVoided: data.incidentState === INCIDENT_STATE.VOID,
    };
};

const transformList = ({ data }) => {
    if (data && !data.data) {
        return data;
    }
    
    return {
        total: data.pagination?.totalResults || 0,
        data: data.data?.map(transformItem)      
    };
};

export default transformList;
