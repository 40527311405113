import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Form, Fieldset, Empty, FormItemDirective } from 'library';
import { useSelector } from 'react-redux';
import { Rules } from 'utils';
import { LookupLocation, LookupLocationExternal } from 'components/lookups';
import { DISTRIBUTION_MODE } from 'config';
import { SelectIncidentType } from 'components/SelectIncidentType';
import IntakeFieldset from '../Fieldset';

const IntakeForm = ({
    readOnly = false,
    categories, directives,
    children,
    onValuesChange,
    loading = false
}) => {
    const [form] = Form.useForm();
    const incident = useSelector(state => state.incident);
    const location = useSelector(state => state.location);
    const { $updating, $loading, $error, ...initialValues } = incident;

    useEffect(() => {
        if (initialValues.locationId) {
            form.resetFields();
        }
    }, [
        initialValues.id,
        initialValues.locationId,
        initialValues.episodeTypeId,
        initialValues.$updatedOn,
        form
    ]);

    const topDirectives = directives?.filter(({ placement }) => placement?.section === 'top' && !placement?.bottom);
    const bottomDirectives = directives?.filter(({ placement }) => placement?.section === 'top' && placement?.bottom);

    return (
        <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            className="container"
            requiredMark={false}
            onValuesChange={onValuesChange}
        >
            <Fieldset
                id="general"
                title="General"
            >
                <LookupLocation
                    disabled={readOnly}
                    defaultFilters={{
                        distributionMode: DISTRIBUTION_MODE.LIVE,
                        titleElastic: location?.title
                    }}
                />
                <LookupLocationExternal
                    disabled={readOnly}
                />
                <Form.Item
                    name="episodeTypeId"
                    label="Incident type"
                    rules={[Rules.required]}
                >
                    <SelectIncidentType
                        disabled={readOnly}
                        placeholder="Required"
                    />
                </Form.Item>
            </Fieldset>

            {topDirectives?.map(item => (
                <FormItemDirective
                    key={item.id}
                    disabled={readOnly}
                    {...item}
                />
            ))}

            {loading && (
                <Skeleton
                    active
                    paragraph={{ rows: 9 }}
                />
            )}

            {!loading && categories?.length === 0 && (
                <Empty
                    description="No Questions Found for this Locaiton and Incident Type"
                />
            )}

            {!(incident.loading || loading) && categories?.map((category) => {
                const dirs = directives?.filter(item => item.placement?.section === category.name);
                return (
                    <IntakeFieldset
                        key={category.id}
                        readOnly={readOnly}
                        category={category}
                        directives={dirs}
                        onValuesChange={onValuesChange}
                    />
                );
            })}

            {bottomDirectives?.map(item => (
                <FormItemDirective
                    key={item.id}
                    disabled={readOnly}
                    {...item}
                />
            ))}
            {children}
        </Form>
    );
};

IntakeForm.propTypes = {
    directives: PropTypes.array,
    categories: PropTypes.array,
    children: PropTypes.element.isRequired,
    onValuesChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    readOnly: PropTypes.bool
};

export default IntakeForm;
