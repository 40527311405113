import ClientApp from 'purecloud-client-app-sdk';
import { GENESYS } from 'config';

class GenesysAppClass {
    client;

    constructor() {
        this.client = new ClientApp({ pcEnvironment: GENESYS.environment });
    }

    alert(msg, title = 'Lintelio') {
        this.client.alerting.showToastPopup(
            title,
            msg,
            {
                type: 'info',
                timeout: 80,
                showCloseButton: true,
                markdownMessage: true,
            }
        );
    }

    onFocus(callback) {
        this.client.lifecycle.addFocusListener(callback);
    }
}

const GenesysApp = new GenesysAppClass();

export { GenesysApp }; 
