/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './Header.scss';
import { Avatar, Watermark } from 'library';
import { UserOutlined } from '@ant-design/icons';
import { ENV, VERSION, isProd } from 'config';
import { useSelector, useDispatch } from 'react-redux';
import { clear as clearConversation } from 'store/call';
import { clear as clearLocation } from 'store/location';
import { Logo } from 'assets';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const watermark = isProd ? '' : ENV;
    
    const goHome = () => {
        dispatch(clearConversation());
        dispatch(clearLocation());
        navigate('/');
    };
    
    return (
        <Watermark
            content={watermark}
            fontSize={14}
            gap={[25, 25]}
            color="#ff0000"
        >
            <header>
                <a onClick={goHome}>
                    <img src={Logo} alt="Lintelio Logo" />
                    <span>v. {VERSION}</span>
                </a>
                {me?.name && (
                    <h5>
                        <Avatar icon={<UserOutlined />} />
                        {me?.name}
                    </h5>
                )}
            </header>
        </Watermark>
    );
};

export default Header;
