/* eslint-disable no-param-reassign */

const transformFacility = (data) => {
    if (data.facility) {
        const { facility } = data;
        if (facility.facilityProviderContactId) {
            data.facility = {
                ...facility,
                id: facility.facilityId,
                type: facility.type,
                facilityProviderContactId: facility.facilityProviderContactId
            };
        }
    }
    return data;
};

const transformUpdate = (data) => {
    let postData = { ...data };

    if (postData.location) {
        postData.locationId = postData.location.id;
    }
    if (data.facility) {
        postData = transformFacility(postData);
    }
    
    return postData;
};

export default transformUpdate;
