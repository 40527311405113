import './Footer.scss';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, ButtonWithConfirm } from 'library';
import { ButtonCallActions, ButtonBack } from 'components/buttons';
import FacilityContext from '../context';
import FacilitySelected from './FacilitySelected';

const NoTriageFooter = () => {
    const form = Form.useFormInstance();
    const { $updating } = useSelector(state => state.incident);
    const { facility, onFacilityChange } = useContext(FacilityContext);
    const isFacilitySelected = !!facility?.name;

    return (
        <footer>
            <ButtonBack />
            <ButtonCallActions />
            <div className="facility-selected">
                {isFacilitySelected ? (
                    <FacilitySelected
                        facility={facility}
                        onClear={onFacilityChange}
                    />
                ) : <>No Facility Selected</>}
            </div>
            {isFacilitySelected ? (
                <Button
                    type="primary"
                    loading={$updating}
                    onClick={form.submit}
                >
                    Continue
                </Button>
            ) : (
                <ButtonWithConfirm
                    placement="topLeft"
                    buttonProps={{ type: 'primary', loading: $updating }}
                    onConfirm={form.submit}
                    title="Facility is not selected"
                    description="Are you sure you want to continue?"

                >
                    Continue
                </ButtonWithConfirm>
            )}
        </footer>
    );
};

export default NoTriageFooter;
