import React, { useContext, useState } from 'react';
import { ButtonValidateIncident } from 'components/buttons';
import { Form } from 'library';
import { INCIDENT_STATE, isProd } from 'config';
import IncidentContext from '../../Incident/context';

const AutoDispatchButton = () => {
    const { onDoneSuccess, update } = useContext(IncidentContext);
    const form = Form.useFormInstance();
    const [loading, setLoading] = useState(false);

    const onClick = () => {
        setLoading(true);
        const values = form.getFieldsValue();
        update({
            ...values,
            incidentState: INCIDENT_STATE.AUTODISPATCH
        })
            .then(onDoneSuccess)
            .finally(() => setLoading(false));
    };

    if (isProd) {
        return null;
    }

    return (
        <ButtonValidateIncident
            onClick={onClick}
            type="dashed"
            loading={loading}
            danger
            title="Auto Callback (for testing only)"
        >
            Auto Callback
        </ButtonValidateIncident>
    );
};
export default AutoDispatchButton;
