import { configureStore } from '@reduxjs/toolkit';

import call from './call';
import me from './me';
import location from './location';
import incident from './incident';

export const store = configureStore({
    reducer: {
        call,
        me,
        location,
        incident
    }
});
