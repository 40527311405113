import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'library';
import ClaimantInput from './ClaimantInput';

const LookupClaimant = ({ config, disabled }) => (
    <Form.Item
        name={['thirdParty', 'contact']}
        noStyle
    >
        <ClaimantInput
            config={config}
            disabled={disabled}
        />
    </Form.Item>
);

LookupClaimant.propTypes = {
    config: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export { LookupClaimant };
