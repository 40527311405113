import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment'; 

const currentYear = dayjs().format('YY');
moment.parseTwoDigitYear = input => +(input) + (+(input) > currentYear ? 1900 : 2000);

const MomentDatePicker = DatePicker.generatePicker(momentGenerateConfig);

const FormatIn = ['MM/DD/YYYY', 'MM/DD/YY', 'MMDDYYYY'];
const FormatOut = 'YYYY-MM-DD';

const InputDate = ({ value, onChange, ...rest }) => {
    const ref = useRef();
    const [thisValue, setThisValue] = useState();
    
    useEffect(() => {
        setThisValue(value ? dayjs(value) : undefined);
    }, [value]);

    const onBeforeChange = (val) => {
        onChange(val ? val.format(FormatOut) : undefined);
    };

    const onInputChange = useCallback((e) => {
        const val = e.target.value;
        if (val === '') {
            onChange('');
        }
    }, [onChange]);

    useEffect(() => {
        const input = ref.current.nativeElement.querySelector('input');
        input.addEventListener('input', onInputChange);
        return () => {
            input.removeEventListener('input', onInputChange);
        };
    }, [onInputChange]);
    
    return (
        <MomentDatePicker
            ref={ref}
            format={FormatIn}
            onChange={onBeforeChange}
            value={thisValue}
            {...rest}
        />
    );
};

InputDate.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export { InputDate };
