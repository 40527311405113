import './NoTriage.scss';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import IncidentSummary from 'components/IncidentSummary';
import { useSelector } from 'react-redux';
import { EsDirectivesService, EsNotesService } from 'services';
import { Grid } from 'layout';
import { FormItemDirective, Form } from 'library';
import { useNavigate } from 'react-router-dom';
import IncidentContext from 'routes/Incident/context';
import { FacilityTypeModal } from './components';
import FacilityTabs from './Tabs';
import Title from './Title';
import FacilityContext from './context';
import Footer from './Footer';

const NoTriage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { update } = useContext(IncidentContext);
    const incident = useSelector(state => state.incident);
    const [directives, setDirectives] = useState([]);
    const [facility, setFacility] = useState();
    const [preferredIds, setPreferredIds] = useState([]);
    const [address, setAddress] = useState();
    const [typeModalOpen, setTypeModalOpen] = useState(false);
    const incidentId = incident.id;

    const loadDirectives = useCallback(() => EsDirectivesService.list({
        locationId: incident.locationId,
        episodeTypeId: incident.episodeTypeId,
    }), [incident.episodeTypeId, incident.locationId]);

    useEffect(() => {
        if (incident.locationId) {
            form.resetFields();
            loadDirectives()
                .then(setDirectives);
        }
    }, [loadDirectives, form, incident.locationId]);

    const facilityDirectives = directives.filter(({ placement }) => placement?.section === 'facility') || [];

    const onFacilityChange = (item) => {
        if (item && !item.type) {
            setTypeModalOpen(item);
        } else setFacility(item);
    };

    const onFacilityTypeOk = (item) => {
        setTypeModalOpen(false);
        setFacility(item);
    };

    const onFacilityTypeCancel = () => {
        setFacility();
        setTypeModalOpen(false);
    };

    useEffect(() => {
        if (incident.facility) {
            const contactId = incident.facilityProviderContactId;
            let selectedId = incident.facility.id;
            if (contactId) {
                selectedId = `${selectedId}-${contactId}`;
            }
            setFacility({
                id: selectedId,
                name: incident.facility.name,
                facilityProviderContactName: incident.facilityProviderContactName
            });
        }
    }, [
        incident.facility,
        incident.facilityProviderContactId,
        incident.facilityProviderContactName
    ]);

    const onFinish = (values) => {
        update({ id: incidentId, facility, ...values })
            .then(() => {
                navigate(`/incidents/${incidentId}/no-triage-summary`);
            });
    };

    return (
        <FacilityContext.Provider
            value={{
                facility,
                onFacilityChange,
                preferredIds,
                setPreferredIds,
                address,
                setAddress
            }}
        >
            <Title />
            <FacilityTypeModal
                open={typeModalOpen}
                onOk={onFacilityTypeOk}
                onCancel={onFacilityTypeCancel}
            />
            <Grid className="grid-no-triage">
                <div className="container">
                    <Form
                        form={form}
                        initialValues={incident}
                        layout="vertical"
                        className="container"
                        requiredMark={false}
                        onFinish={onFinish}
                    >
                        <div className="container-directives">
                            {facilityDirectives.filter(({ placement }) => !placement?.bottom)
                                .map(item => (
                                    <FormItemDirective
                                        key={item.id}
                                        {...item}
                                    />
                                ))}
                        </div>
                        <FacilityTabs />
                        <div className="container-directives">
                            {facilityDirectives.filter(({ placement }) => placement?.bottom)
                                .map(item => (
                                    <FormItemDirective
                                        key={item.id}
                                        {...item}
                                    />
                                ))}
                        </div>
                        <Footer />
                    </Form>
                </div>

                <IncidentSummary
                    noteType={EsNotesService.ALL_TYPE}
                />
            </Grid>

        </FacilityContext.Provider>
    );
};

export default NoTriage;
