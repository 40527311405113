/* eslint-disable jsx-a11y/click-events-have-key-events */
import './IncidentCard.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'library';

const IncidentCard = ({ onClick, incident, children, loading = false, className = '' }) => (
    <Spin spinning={loading}>
        <div
            className={`incident-card ${className}`}
            onClick={onClick || null}
            role="button"
            tabIndex="0"
            style={onClick ? { cursor: 'pointer' } : null}
        >
            <div className={`incident-type-icon incident-type-icon-${incident.$incidentType?.abbr}`} />
            <div>
                {children}
            </div>
        </div>
    </Spin>
);

IncidentCard.propTypes = {
    incident: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.any.isRequired,
    loading: PropTypes.bool,
    className: PropTypes.string
};

export { IncidentCard };
