import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'library';
import { SubHeader } from 'layout';
import { LogoGenesys } from 'assets';

import TestTransfer from './states';
import ApiPlayground from './api';
import GenesysIncident from './genesysIncident';

const TestRoutes = () => {
    const call = useSelector(state => state.call);
    const convId = call.id;
    useEffect(() => {
        if (convId) {
            notification.info({ message: `New Conversation: ${convId}` });
        }
    }, [convId]);

    return (
        <>
            <ApiPlayground />
            <SubHeader
                prefix={<img src={LogoGenesys} alt="" />} 
                title="Genesys Playground: get incident"
            />
            <div style={{ margin: 40, width: 600 }}>
                <GenesysIncident />
            </div>

            <SubHeader
                prefix={<img src={LogoGenesys} alt="" />} 
                title="Genesys Playground: transfer to RN"
            />
            
            <div style={{ margin: 40, display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: 10, width: 600 }}>
                <TestTransfer />
            </div>

        </>
    );
};

export default TestRoutes;
