import './waiting.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'library';
import { ImageWaiting } from 'assets';

const CallerIdWaiting = ({ startWithoutCall, cleanUpIncident }) => {
    useEffect(() => {
        cleanUpIncident();
    }, [cleanUpIncident]);

    return (
        <div className="waiting-call">
            <div>
                <img src={ImageWaiting} alt="Start without a call" />
                <Button
                    block
                    size="large"
                    type="primary"
                    onClick={startWithoutCall}
                >
                    Start without a call
                </Button>
            </div>
        </div>
    );
};

CallerIdWaiting.propTypes = {
    startWithoutCall: PropTypes.func.isRequired,
    cleanUpIncident: PropTypes.func.isRequired
};

export default CallerIdWaiting;
