import './filter.select.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Button } from 'library';

const FilterSelect = ({
    selectedKeys = [], 
    setSelectedKeys,
    confirm, filters, 
    clearFilters
}) => {
    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(selectedKeys);
    }, [selectedKeys]);

    const handleClearFilters = () => {
        setValue();
        clearFilters();
        confirm();
    };

    const handleSearch = () => {
        if (value) {
            setSelectedKeys(value);
            confirm();
        } else {
            clearFilters();
        }
    };

    return (
        <div className="filter-select">
            <Select
                options={filters}
                onChange={val => setValue(val)}
                size="middle"
                allowClear
                className="filter-select"
            />
            <div className="lx-table-filter-dropdown-btns">
                <Button
                    type="link"
                    size="small"
                    onClick={() => handleClearFilters()}
                    className="lx-table-filter-dropdown-link clear"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => handleSearch()}
                    className="lx-table-filter-dropdown-link confirm"
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

FilterSelect.propTypes = {
    selectedKeys: PropTypes.array,
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    clearFilters: PropTypes.func.isRequired,
};

export { FilterSelect };
