export const InjurySurveyQuestions = [
    { 
        name: 'injury_description', 
        label: 'How did the accident happen?',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_nature_of', 
        label: 'Describe your Medical Complaint',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_object_involved', 
        label: 'Object, Equipment or Substance',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_activity_description', 
        label: 'Activity Employee Doing',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_pertinent_medical_history', 
        label: 'Medical History',
        maxLength: 500,
        type: 'TEXTAREA',
    },
];
