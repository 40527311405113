import './FacilityTypeModal.scss';
import PropTypes from 'prop-types';
import { Modal, Form } from 'library';
import { Select } from 'antd';
import { Rules } from 'utils';
import { FacilityTypeOptions } from 'config';

const FacilityTypeModal = ({ open, onOk, onCancel }) => {
    const [form] = Form.useForm();

    const handleFinish = () => {
        form.validateFields().then((values) => {
            form.resetFields();
            onOk({ ...open, type: values.facilityType });
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    return (
        <Modal
            className="info modal-choose-to-continue"
            title="Facility Type Required"
            open={!!open}
            onOk={handleFinish}
            onCancel={handleCancel}
            centered
        >
            <div className="content">
                <Form
                    form={form}
                    layout="vertical"
                    hideRequiredMark
                >
                    <div className="facility-type-content">
                        <p>
                            The selected facility has not been verified
                            since it is coming from a third party source.
                            Please inform the caller this is the case and
                            choose what the facility type is for this particular incident
                        </p>
                        <Form.Item
                            label="Facility Type"
                            name="facilityType"
                            rules={[Rules.required]}
                        >
                            <Select
                                options={FacilityTypeOptions}
                                placeholder="Select Facility Type"
                                allowClear
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

FacilityTypeModal.propTypes = {
    open: PropTypes.any.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export { FacilityTypeModal };
