import './Layout.scss';
import { Outlet } from 'react-router-dom';
import Header from './Header';

export * from './Grid';
export * from './SubHeader';

const Layout = () => (
    <>
        <Header />
        <Outlet />
    </>
);

export { Layout };
