export const DISTRIBUTION_STATUS = {
    ERROR: -1,
    PENDING: 0, // Pending
    PROCESSING: 1, // Processing
    DONE: 2, // Success
    OUTSIDE_BUSINESS_HOURS: 3, // Outside Business Hours
};

export const DISTRIBUTION_SEND = {
    SMS: 'sms',
    FAX: 'fax',
    EMAIL: 'email',
    API: 'api' 
};
