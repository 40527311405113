export const DISTRIBUTION_MODE = {
    TEST: 1, 
    BETA: 2, 
    LIVE: 3, 
    OFF: 4, 
};

export const DISTRIBUTION_MODE_MAP = {
    [DISTRIBUTION_MODE.TEST]: 'Test',
    [DISTRIBUTION_MODE.BETA]: 'Beta',
    [DISTRIBUTION_MODE.LIVE]: 'Active',
    [DISTRIBUTION_MODE.OFF]: 'Off',
};

export const ORGANIZATION_CONTACT_ROLES = {
    // 20: 'Primary Contact',
    28: 'Insurance Carrier/TPA',
    23: 'Return To Work Coordinator',
    24: 'CN Account Executive'
};

export const SSN_SHOW_ALL = 9;
export const SNN_SHOW_LAST_FOUR = 4;
