import { useState } from 'react';
import { Button, notification } from 'library';
import { GnConversationService } from 'services';

const StateAttributes = [
    { name: 'AK - Alaska', success: 1 },
    { name: 'AL - Alabama', success: 1 },
    { name: 'AR - Arkansas', success: -1 },
    { name: 'AZ - Arizona', success: -1 },
    { name: 'CA - California', success: -1 },
    { name: 'CO - Colorado', success: -1 },
    { name: 'CT - Connecticut', success: 1 },
    { name: 'DC - District of Columbia', success: 0 },
    { name: 'DE - Delaware', success: 0 },
    { name: 'FL - Florida', success: -1 },
    { name: 'GA - Georgia', success: 0 },
    { name: 'HI - Hawaii', success: 1 },
    { name: 'IA - Iowa', success: 0 },
    { name: 'ID - Idaho', success: 0 },
    { name: 'IL - Illinois', success: -1 },
    { name: 'IN - Indiana', success: 0 },
    { name: 'KS - Kansas', success: 0 },
    { name: 'KY - Kentucky', success: 0 },
    { name: 'LA - Louisiana', success: 0 },
    { name: 'MA - Massachusetts', success: 1 },
    { name: 'MD - Maryland', success: 0 },
    { name: 'ME - Maine', success: 0 },
    { name: 'MI - Michigan', success: 0 },
    { name: 'MN - Minnesota', success: 0 },
    { name: 'MO - Missouri', success: 0 },
    { name: 'MS - Mississippi', success: 0 },
    { name: 'MT - Montana', success: -1 },
    { name: 'NC - North Carolina', success: 0 },
    { name: 'ND - North Dakota', success: 0 },
    { name: 'NE - Nebraska', success: 0 },
    { name: 'NH - New Hampshire', success: 0 },
    { name: 'NJ - New Jersey', success: 0 },
    { name: 'NM - New Mexico', success: 0 },
    { name: 'NV - Nevada', success: -1 },
    { name: 'NY - New York', success: 0 },
    { name: 'OH - Ohio', success: 0 },
    { name: 'OK - Oklahoma', success: 0 },
    { name: 'OR - Oregon', success: 0 },
    { name: 'PA - Pennsylvania', success: 0 },
    { name: 'PR - Puerto Rico', success: -1 },
    { name: 'RI - Rhode Island', success: 1 },
    { name: 'SC - South Carolina', success: 0 },
    { name: 'SD - South Dakota', success: 0 },
    { name: 'TN - Tennessee', success: 0 },
    { name: 'TX - Texas', success: 0 },
    { name: 'UT - Utah', success: 0 },
    { name: 'VA - Virginia', success: 0 },
    { name: 'VT - Vermont', success: 1 },
    { name: 'WA - Washington', success: 0 },
    { name: 'WI - Wisconsin', success: 0 },
    { name: 'WV - West Virgina', success: 0 },
    { name: 'WY - Wyoming', success: -1
    }
];

export default () => {
    const [loading, setLoading] = useState();
    
    const transfer = (SelectedState) => {
        setLoading(SelectedState);
        GnConversationService.conversations.transferToRN(SelectedState)
            .catch((e) => {
                notification.error({ message: e.message });
            })
            .finally(() => setLoading());
    };
    return StateAttributes
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(state => (
            <Button
                key={state.name}
                onClick={() => transfer(state.name)}
                loading={loading === state.name}
                type={state.success === 1 ? 'primary' : 'default'}
                danger={state.success === -1}
            >
                {state.name}
            </Button>
        ));
    };
