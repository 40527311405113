import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'library';
import { ButtonValidateIncident } from 'components/buttons';

const ButtonCreateIncident = ({ creating }) => {
    const form = Form.useFormInstance();

    return (
        <ButtonValidateIncident
            loading={creating} 
            onClick={form.submit}
        >
            Create Incident
        </ButtonValidateIncident>
    );
};

ButtonCreateIncident.propTypes = {
    creating: PropTypes.bool.isRequired,
};

export { ButtonCreateIncident };
