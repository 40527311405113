/* eslint-disable max-len */
import { formatSsn, formatDate } from 'utils';
import dayjs from 'dayjs';

export default {
    source: 'Randstad',
    sourceType: 'contact',
    title: 'Randstad',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    transform: (data) => {
        // https://objectsystems.atlassian.net/browse/LIN-7059
        data.sort((a, b) => {
            const bigData = '12/12/3000';
            const aEnd = dayjs(a.employmentEnd || bigData);
            const bEnd = dayjs(b.employmentEnd || bigData);

            if (aEnd.isSame(bEnd)) {
                const aStart = dayjs(a.employmentBegin || bigData);
                const bStart = dayjs(b.employmentBegin || bigData);
                return aStart.isBefore(bStart) ? 1 : -1;
            }

            return aEnd.isBefore(bEnd) ? 1 : -1;
        });
        return data;
    },
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 140,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            width: 140,
        },
        {
            label: 'SSN',
            source: 'ssn',
            filterType: 'ssn',
            target: 'claimant_ssn', 
            render: formatSsn,
        }, 
        {
            label: 'Employment Begin',
            source: 'employmentBegin',
            target: 'employment_hired_date',
            render: formatDate
        },
        {
            label: 'Employment End',
            source: 'employmentEnd',
            render: formatDate
        },
        {
            label: 'Employee Id',
            source: 'employeeId',
            target: 'claimant_employee_id',
        },
        {
            label: 'Occupation',
            source: 'jobTitle',
            target: 'employment_job_title_or_occupation',
            width: 220,
        },
        {
            label: 'Email',
            source: 'email',
            target: 'claimant_email_address',
        },
        {
            label: 'Address',
            source: 'address',
            target: 'employer_location_address',
            width: 200,
        },
        {
            label: 'City',
            source: 'city',
            target: 'employer_location_city',
        },
        {
            label: 'State',
            source: 'state',
            target: 'employer_location_state',
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'employer_location_zip',
        },
        {
            label: 'Job Desc',
            source: 'jobDesc',
            target: 'employment_job_description',
            width: 160,
        },
        {
            label: 'Work Site',
            source: 'workSite',
            target: 'employer_location_name', 
            width: 200,
        },
        {
            label: 'Corporate',
            source: 'corporate',
        },
        {
            label: 'Segment',
            source: 'segment',
            width: 70,
        },
        {
            label: 'Status',
            source: 'status',
            width: 70,
        },
        {
            label: 'Sequence Num',
            source: 'sequenceNum',
            target: 'sequenceNum',
        },
        {
            label: 'Business Unit (BU) number',
            source: 'rklNodeCode',
            target: 'randstad_bu_number',
        },
        {
            label: 'Region Name',
            source: 'rklLocation9Name',
            target: 'randstad_region_name',
        },
        {
            label: 'Line-Of-Business Name',
            source: 'rklLocation4Name',
            target: 'randstad_line_of_business_name',
        }
    ],
    /*
    onSelect: async (item) => {
        const res = { ...item };
        if (res?.fields) {
            res.fields.employer_location_city = res.fields.claimant_city;
            res.fields.employer_location_state = res.fields.claimant_state;
            res.fields.employer_location_zip = res.fields.claimant_zip;
        } 
        return res;
    } */
};
