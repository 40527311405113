import './FilterFacilityNetwork.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'library';
import { EsFacilitiesService } from 'services';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import FilterCheckboxes from '../../../../library/Table/filters/filter.checkboxes';

const sortNetworks = (a, b) =>
    (b.favorite - a.favorite)
    || (b.isDesignated - a.isDesignated)
    || a.name.localeCompare(b.name);

const FilterFacilityNetwork = ({
    locationId, ...rest
}) => {
    const [networks, setNetworks] = useState([]);
    const [loading, setLoading] = useState();

    useEffect(() => {
        if (locationId) {
            setLoading(true);

            Promise.all([
                EsFacilitiesService.networksByLocationId(locationId),
                EsFacilitiesService.networks()
            ])
                .then(([designated, all]) =>
                    all.map((item) => {
                        const desItem = designated.find(it => it.id === item.id);
                        const isDesignated = !!(desItem ? 1 : 0);
                        return { ...item, ...(desItem || {}), isDesignated };
                    })
                        .sort(sortNetworks)
                )
                .then(setNetworks)
                .finally(() => setLoading(false));
        }
    }, [locationId]);

    if (loading || !locationId) {
        return <Spin spinning />;
    }

    return (
        <div className="filter-facility-network">
            <FilterCheckboxes
                {...rest}
                filters={networks.map(item => ({
                    value: item.id,
                    label: item.name,
                    display: (
                        <div className="with-icon">
                            {!!item.favorite && <StarFilled />}
                            {!item.favorite && !!item.isDesignated && <StarOutlined />}
                            {item.name}
                        </div>
                    )
                }))}
            />
        </div>
    );
};

FilterFacilityNetwork.propTypes = {
    locationId: PropTypes.number
};

export { FilterFacilityNetwork };
