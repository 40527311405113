import './OpenHours.scss';
import PropTypes from 'prop-types';
import { InfoCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'library';

const DoW = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const FacilityOpenHours = ({ item }) => {
    const { openHours, closed } = item;
  
    if (!openHours || Object.isUndefined(closed)) {
        return <QuestionCircleFilled />;
    }
    
    const { isNonStop, ...days } = openHours;

    if (!isNonStop && !Object.keys(days).length) {
        return <QuestionCircleFilled />;
    }

    const isOpen = !closed;

    const title = DoW.map((label) => {
        const shortLabel = label.substring(0, 3);
        const key = label.toLowerCase();
        if (isNonStop) {
            return (
                <div key={key}>
                    <i>{shortLabel}</i>
                    <span>Open</span>
                </div>
            );
        }

        const day = days[key];
        if (day) {
            const start = day.from;
            const end = day.to;
    
            if (start && end) {
                return (
                    <div key={key}>
                        <i>{shortLabel}</i>
                        <span>{start}</span>
                        <span>-</span>
                        <span>{end}</span>
                    </div>
                );
            }
        }
        
        return (
            <div key={key}>
                <i>{shortLabel}</i>
                <span>Closed</span>
            </div>
        );
    });

    return (
        <Tooltip
            overlayClassName="hours-info-popover"
            placement="top"
            title={title}
        >
            <span className="with-icon hours-info">
                <InfoCircleFilled className={isOpen ? 'success' : 'info'} />
                {isOpen ? 'Open' : 'Closed'}
                {isNonStop ? ' 24/7' : ''}
            </span>
        </Tooltip>
    );
};

FacilityOpenHours.propTypes = {
    item: PropTypes.object.isRequired
};

export { FacilityOpenHours };
