/* eslint-disable max-len */
import { formatSsn, formatDate, formatDateOut } from 'utils';

export default {
    source: 'Rubio HR',
    sourceType: 'contact',
    title: 'Rubio HR',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            required: true,
            width: 90
        },
        {
            label: 'SSN',
            source: 'ssn',
            target: 'claimant_ssn',
            filter: 'claimant_ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            source: 'dob',
            target: 'claimant_birth_date',
            render: formatDate,
            targetFn: formatDateOut,
            width: 90
        },
        {
            label: 'Employee Id',
            target: 'claimant_employee_id',
            source: 'employeeId',
            filter: 'employeeId',
            width: 100
        },
        {
            label: 'Occupation',
            source: 'jobTitle',
            target: 'employment_job_title_or_occupation',
            width: 100
        },
        {
            label: 'Hire Date',
            source: 'hireDate',
            target: 'employment_hired_date',
            render: formatDate,
            targetFn: formatDateOut,
            width: 90
        },
        {
            label: 'Gender',
            source: 'gender',
            target: 'claimant_gender_code',
            targetFn: value => (
                {
                    M: '1',
                    F: '2'
                }[value] || undefined),
            width: 60
        },
        {
            label: 'Department',
            source: 'department',
            target: 'employment_department_name',
            width: 100
        },
    ]
};
