import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FloatButton } from 'library';
import { useParams } from 'react-router-dom';
import { ButtonCallActions } from 'components/buttons';
import { IncidentDuplicatesIntake } from 'components/IncidentDuplicates';
import MarkVoidButton from './MarkVoidButton';
import SendToTriageButton from './SendToTriageButton';
import ReportOnlyButton from './ReportOnlyButton';
import NextIntakeButton from './NextIntakeButton';
import AutoDispatchButton from './AutoDispatchButton';

const IntakeEditFooter = ({ disabled }) => {
    const { id, submitted, $isReportOnly } = useSelector(state => state.incident);
    const { nextId } = useParams();

    let ContinueButton = <SendToTriageButton disabled={disabled} />;

    if ($isReportOnly) {
        ContinueButton = <ReportOnlyButton />;
    }

    // !submitted && relatedIncident && !relatedIncident.submitted
    if (nextId) {
        ContinueButton = (
            <NextIntakeButton
                nextId={nextId}
                disabled={disabled}
            />
        );
    }

    return (
        <footer>
            {id && !submitted && (
                <IncidentDuplicatesIntake />
            )}
            <FloatButton.BackTop />
            <ButtonCallActions />
            <AutoDispatchButton />
            <MarkVoidButton />
            {ContinueButton}
        </footer>
    );
};

IntakeEditFooter.propTypes = {
    disabled: PropTypes.bool.isRequired,
};

export default IntakeEditFooter;
