import './Help.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

const Help = ({ children = '', tooltip, placement = 'top' }) => (
    <Tooltip
        placement={placement}
        title={tooltip}
        className="help"
    >
        {children}
        <InfoCircleFilled />
    </Tooltip>
);

Help.propTypes = {
    children: PropTypes.any,
    tooltip: PropTypes.any.isRequired,
    placement: PropTypes.string,
};

export { Help };
