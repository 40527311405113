import { PlusSquareFilled, SolutionOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { FacilityTypeIcon } from 'config';
import { FacilityOpenHours } from 'components/Facility/OpenHours';

export const renderType = (value, row) =>
    (row.facilityProviderContactId ? null : FacilityTypeIcon[value]);

export const renderName = (value, row) => (
    <div className="with-icon">
        {!!row.external && <PlusSquareFilled />}
        {!row.facilityProviderContactId ? value : ''}
        {row.facilityProviderContactId && (
            <>
                <SolutionOutlined />
                {row.facilityProviderContactName}
            </>
        )}
    </div>
);

export const renderFacilityDistance = (value, row) => {
    if (typeof value === 'number') {
        return `${value.toFixed(2)} miles`;
    }
    return !row.facilityProviderContactId ? 'Destination Unknown' : null;
};

export const renderOpenHours = (_, item) =>
    (!item.facilityProviderContactId ? <FacilityOpenHours item={item} /> : null);

export const renderPreffered = (value, row) => {
    if (row.facilityProviderContactId) {
        return null;
    }
    return (value ? <StarFilled /> : <StarOutlined />);
};
