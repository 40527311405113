import { QUESTION_TYPE_MAP, FIELD_TYPE_ACTION_CREATE_WV_INCIDENT } from 'config';

export const transformOptions = (item) => {
    if (!Array.isArray(item.options)) {
        return undefined;
    }

    return item.options.map(op => ({
        value: Object.isDefined(op.id) ? op.id : op.value,
        label: (Object.isDefined(op.text) ? op.text : op.title) || '',
        code: op.code || ''
    }));
};

/*
const testQuestions = ; */

const addDynamicQuestions = (questions) => {
    const hasWV = questions.find(item => item.name === 'wpv_incident_involving_workplace_violence');
    const actionQuestion = {
        id: -999,
        categoryId: 1,
        name: 'action_add_wv_incident',
        title: '',
        type: FIELD_TYPE_ACTION_CREATE_WV_INCIDENT,
        showIf: {
            all: hasWV
                ? [{
                    fieldName: 'wpv_incident_involving_workplace_violence',
                    operator: 'equals',
                    value: 'yes',
                }] : [{
                    fieldName: 'caller_first_name',
                    operator: 'equals',
                    value: 'Blaga',
                }]
        },
    };
    return [
        ...questions,
        actionQuestion
    ];
};

const transformList = (id, { data: { data } }, allCategories) => {
    const questions = addDynamicQuestions(data);

    const getQuestions = categoryId => questions
        .filter(item => item.categoryId === categoryId)
        .map(item => ({
            id: item.id,
            name: item.name,
            label: item.customTitle || item.title || '',
            required: !!parseInt(item.required, 10),
            disabled: !!parseInt(item.readonly, 10),
            estimated: !!parseInt(item.estimated, 10),
            maxLength: item.maxLength,
            categoryId: item.categoryId,
            options: transformOptions(item),
            type: (QUESTION_TYPE_MAP[item.type] || 'N/A'),
            showIf: item.showIf,
            copy: item.dataRelation?.copy,
            fetch: item.fetch,
        }));
    const categories = questions
        .map(item => item.categoryId)
        .unique()
        .map((cid) => {
            const category = allCategories.find(it => it.id === cid);
            if (!category) {
                return undefined;
            }
            return {
                id,
                ...category,
                questions: getQuestions(category.id)
            };
        }).filter(item => !!item)
        .sort((a, b) => (a.ord > b.ord ? 1 : -1));

    return categories;
};

export default transformList;
