/* eslint-disable max-len */
export default {
    source: 'Psw API',
    sourceType: 'contact',
    title: 'Public School Works',
    transform: data => data.sortByString('firstName'),
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            required: true,
            width: 120,
        },
        {
            label: 'Occupation',
            source: 'primaryOccupation',
            target: 'employment_job_title_or_occupation',
            width: 150
        },
        {
            label: 'Email',
            source: 'email',
            target: 'claimant_email_address',
            width: 120
        },
        {
            label: 'Mobile Phone',
            source: 'phoneNumber',
            target: 'claimant_mobile_phone',
            width: 150
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 100
        },
        {
            label: 'State',
            target: 'claimant_state',
            source: 'state',
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
        {
            label: 'Department',
            source: 'department',
            target: 'employment_department_name',
            width: 150
        },
        {
            label: 'District',
            source: 'district',
            width: 150
        },
    ]
};
