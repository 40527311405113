import './IncidentType.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Select } from 'library';
import { getIncidentType } from 'config';

const SelectIncidentType = ({ isNew = false, value, onChange, ...rest }) => {
    const { episodeTypeId } = useSelector(state => state.location);
    const { incidentTypeId: callIncidentTypeId } = useSelector(state => state.call);
    const { abbr } = getIncidentType(value);

    const incidentTypeOptions = episodeTypeId?.map(item => ({
        value: item.id,
        label: item.title,
    }));

    useEffect(() => {
        if (isNew && callIncidentTypeId) {
            onChange(callIncidentTypeId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callIncidentTypeId, isNew]);

    return (
        <Select
            value={value}
            onChange={onChange}
            placeholder="Select an Incident Type"
            options={incidentTypeOptions}
            className={`incident-type-select ${abbr}`}
            {...rest}
        />
    );
};

SelectIncidentType.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    isNew: PropTypes.bool
};

export { SelectIncidentType };
