import './Grid.scss';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import Context from './context';

const Grid = ({ children, className = '' }) => {
    const [expanded, setExpanded] = useState(false);

    const ChangeLayoutIcon = expanded ? CaretRightOutlined : CaretLeftOutlined;

    return (
        <Context.Provider
            value={{
                expanded,
                setExpanded
            }}
        >
            <div id="grid" className={`${expanded ? 'grid-expanded' : ''} ${className}`}>
                <div id="left">
                    {children[0]}
                </div>
                <div
                    id="middle"
                    onClick={() => setExpanded(!expanded)}
                    aria-hidden="true"
                >
                    <ChangeLayoutIcon />
                </div>
                <div id="right">
                    {children[1]}
                </div>
            </div>
        </Context.Provider>
    );
};

Grid.propTypes = {
    children: PropTypes.array.isRequired,
    className: PropTypes.string
};

export { Grid };
