import { formatPhone } from 'utils';
import { FacilityServices, Notes } from 'components/Facility';
import {
    renderFacilityDistance,
    renderType,
    renderName,
    renderOpenHours,
    renderPreffered,
} from '../../components';

export default ([
    {
        title: '',
        dataIndex: 'isPreferred',
        width: 35,
        fixed: true,
        render: renderPreffered,
    },
    {
        title: 'ER',
        dataIndex: 'type',
        fixed: true,
        width: 35,
        render: renderType,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        fixed: true,
        width: 200,
        render: renderName
    },
    {
        title: 'Code',
        dataIndex: 'code',
        width: 100,
    },
    {
        title: 'Proximity',
        dataIndex: 'distance',
        render: renderFacilityDistance
    },
    {
        title: 'Open Hours',
        dataIndex: 'openHours',
        width: 100,
        render: renderOpenHours
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        width: 100,
        render: formatPhone
    },
    {
        title: 'Fax',
        dataIndex: 'fax',
        width: 100,
        render: formatPhone
    },
    {
        title: 'City',
        width: 100,
        dataIndex: 'city',
    },
    {
        title: 'State',
        width: 100,
        dataIndex: 'state',
    },
    {
        title: 'Zip',
        width: 100,
        dataIndex: 'zip',
    },
    {
        title: 'Notes',
        dataIndex: 'referralNotes',
        width: 400,
        render: value => <Notes text={value} />
    },
    {
        title: 'Services',
        dataIndex: 'services',
        fixed: 'right',
        width: 65,
        render: (_, item) => <FacilityServices item={item} />
    },
]);
