import React from 'react';
import { useSelector } from 'react-redux';
import { ORGANIZATION_CONTACT_ROLES } from 'config';
import { formatPhone } from 'utils';

const SummaryEmployerContacts = () => {
    const { organizationContacts } = useSelector(state => state.location);

    return (
        <>
            <h2>Employer Contacts</h2>
            {organizationContacts && (
                <div className="data-grid">
                    {Object.keys(ORGANIZATION_CONTACT_ROLES).map((roleId) => {
                        const contact = organizationContacts
                            .find(item => item.roleId.toString() === roleId.toString());
                        if (!contact) {
                            return null;
                        }
                        const fullName = `${contact.firstName || ''} ${contact.lastName || ''}`;
                        const { email, workPhone: phone } = contact;
                        return (
                            <React.Fragment key={roleId}>
                                <div> {ORGANIZATION_CONTACT_ROLES[roleId]} </div>
                                <span>
                                    {fullName}
                                    {(fullName.length > 1 && contact?.company) ? ', ' : ''}
                                    {contact?.company}
                                    {email ? (<div><a href="mailto: email">{email}</a></div>) : null}
                                    {phone ? (<div>{formatPhone(phone)}</div>) : null}
                                </span>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default SummaryEmployerContacts;
