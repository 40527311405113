import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button } from 'library';
import { ButtonCallActions } from 'components/buttons';

const NoTriageSummaryFooter = () => {
    const form = Form.useFormInstance();
    const { $updating } = useSelector(state => state.incident);
    
    return (
        <footer>
            <ButtonCallActions />
            <Button
                type="primary"
                onClick={form.submit}
                loading={$updating}
            >
                Finish
            </Button>
        </footer>
    );
};

export default NoTriageSummaryFooter;
