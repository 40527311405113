import React, { useContext } from 'react';
import { ButtonCallActions } from 'components/buttons';
import { ButtonWithConfirm, Form } from 'library';
import { useSelector, useDispatch } from 'react-redux';
import { clear as clearConversation } from 'store/call';
import { clear as clearLocation } from 'store/location';
import { ButtonCreateIncident } from './CreateIncident';

import Context from '../context';

const CallerIdFooter = () => {
    const dispatch = useDispatch();
    const form = Form.useFormInstance();
    const call = useSelector(state => state.call);
    const { setInterpreter, creating } = useContext(Context);

    const onCancelClick = () => {
        dispatch(clearConversation());
        dispatch(clearLocation());
        form.resetFields();
    };

    return (
        <footer>
            {call.isConnected && (
                <>
                    <ButtonCallActions
                        setInterpreter={setInterpreter}
                    />
                    <ButtonWithConfirm
                        okText="Yes"
                        cancelText="No"
                        buttonProps={{ type: 'link' }}
                        type="link"
                        onConfirm={onCancelClick}
                        description="Are you sure you want to discard the creation of the incident?"
                    >
                        Cancel
                    </ButtonWithConfirm>
                    <ButtonCreateIncident
                        creating={creating}
                    />
                </>
            )}
        </footer>
    );
};

export default CallerIdFooter;
