/* eslint-disable no-nested-ternary */
import { FACILITY_SOURCE } from 'config';
import transformContacts from './transform.contacts';

const transformList = (data) => {
    const res = data.map(item => ({
        ...item,
        priority: item.isPreferred ? 100 : (item.source > FACILITY_SOURCE.INTERNAL ? 0 : 10),
        children: transformContacts(item)
    }));
    return res;
};

export default transformList;
