import './Distributions.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useService } from 'hooks';
import { EsDistributionsService } from 'services';
import { useSelector } from 'react-redux';
import { Empty, Button, Spin } from 'library';
import { RedoOutlined, CheckCircleFilled, CloseCircleFilled, PauseCircleFilled, ClockCircleFilled, MoonFilled } from '@ant-design/icons';
import Context from 'layout/Grid/context';

import ResendModal from './ResendModal';

const RESULT_RULE_SKIPPED = 900;

const Distributions = () => {
    const { expanded } = useContext(Context);
    const [distribution, setDistribution] = useState();
    const { id: incidentId } = useSelector(state => state.incident);
    const service = EsDistributionsService.list;
    const [load, { data: distributions, loading }] = useService(service, [], ({ data }) => data);

    // const [download] = useService(service.download, []);

    useEffect(() => {
        if (incidentId) {
            load(incidentId, { resultCode: { value: RESULT_RULE_SKIPPED, pattern: 'NOT' } });
        }
    }, [incidentId, load, expanded]);

    const onSuccess = () => {
        load(incidentId);
        setDistribution();
    };

    const iconsMap = {
        Success: <CheckCircleFilled />,
        Error: <CloseCircleFilled />,
        Pending: <PauseCircleFilled />,
        Processing: <ClockCircleFilled />,
        'Outside Business Hours': <MoonFilled />
    };

    return (
        <>
            <h2>Distribution Log</h2>
            {distribution && (
                <ResendModal
                    setDistribution={setDistribution}
                    distribution={distribution}
                    onSuccess={onSuccess}
                />
            )}
            <Spin spinning={!!loading}>
                {!distributions.length && (
                    <Empty />
                )}
                <div className="distributions-panel">
                    {distributions.map(item => (
                        <React.Fragment key={item.id}>
                            <div className="distribution-item">
                                <span>{iconsMap[item.statusDesc]}</span>
                                <div>
                                    <h4>
                                        <div>{item.statusDesc}: {item.resultCodeDesc}</div>
                                        <div className="distribution-icons">
                                            <Button
                                                size="small"
                                                icon={<RedoOutlined />}
                                                title="Re-Send"
                                                onClick={() => setDistribution(item)}
                                            />
                                            { /* 
                                            <Button
                                                disabled={!item.hasFile}
                                                size="small"
                                                icon={<DownloadOutlined />}
                                                title="Download"
                                                onClick={() => download(item.id)}
                                            /> 
                                            */ }
                                        </div>
                                    </h4>
                                    <h3>{item.reportTitle || 'N/A'}</h3>
                                    <div> {item.createdDate} </div>
                                    <div> Sent via {item.sendVia} to:</div>
                                    <ul>
                                        <li>{item.sendTo || 'N/A'} ({item.roleName})</li>
                                        {item.emailExtraRecipients?.map(it => (
                                            <li>{it.email} ({it.roleName})</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div />
                        </React.Fragment>
                    ))}
                </div>
            </Spin>
        </>
    );
};

export default Distributions;
