import { Input, Select, Form, InputPhone, InputSsn } from 'library';
import PropTypes from 'prop-types';
import { Rules } from 'utils';

const InputFilter = ({
    name, label, onChange,
    options, required = false, filterType
}) => {
    const form = Form.useFormInstance();

    const props = {
        size: 'middle',
        placeholder: label,
        title: label,
        autoComplete: 'nope'
    };

    const onPressEnter = (e) => {
        e?.preventDefault();
        form.submit();
    };

    return (
        <Form.Item
            noStyle
            name={name}
            rules={required ? [Rules.required] : []}
            title={label}
        >
            {options && (
                <Select
                    options={options}
                    popupMatchSelectWidth={false}
                    onChange={() => {
                        onChange(true);
                        form.submit();
                    }}
                    allowClear
                    {...props}
                />
            )}
            {filterType === 'phone' && (
                <InputPhone
                    {...props}
                    onChange={() => onChange()}
                    onPressEnter={onPressEnter}
                />
            )}
            {filterType === 'ssn' && (
                <InputSsn
                    {...props}
                    onPressEnter={onPressEnter}
                />
            )}
            {!options && !filterType && (
                <Input
                    {...props}
                    onChange={() => onChange()}
                    rules={[{ pattern: /^[a-zA-Z0-9]*$/ }]}
                    onPressEnter={onPressEnter}
                    allowClear
                />
            )}
        </Form.Item>
    );
};

InputFilter.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    required: PropTypes.bool,
    filterType: PropTypes.string
};

export default InputFilter;
