import PropTypes from 'prop-types';
import { DatePicker, TimePicker, Space } from 'antd';

const InputDateTime = ({ value, onChange, ...rest }) => (
    <Space.Compact block>
        <DatePicker
            format="MM/DD/YYYY"
            onChange={onChange}
            {...rest}
        />
        <TimePicker />
    </Space.Compact>
);

InputDateTime.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export { InputDateTime };
