import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonValidateIncident } from 'components/buttons';

const IntakeReportOnlyButton = () => {
    const { id } = useParams(); // @TODO remove from here and leaveit to onDone
    const navigate = useNavigate();
    
    const onClick = () => {
        navigate(`/incidents/${id}/report-only-summary`);
    };

    return (
        <ButtonValidateIncident
            onClick={onClick}
        >
            Continue
        </ButtonValidateIncident>
    );
};

export default IntakeReportOnlyButton;
