import {
    LanguageOptions, INCIDENT_TYPE_ATTRIBUTE,
    INCIDENT_TYPES,
} from 'config';
import AuthService from '../auth.service';

const ParticipantState = {
    terminated: 'terminated',
    alerting: 'alerting',
    connected: 'connected',
    disconnected: 'disconnected'
};

const addressToPhone = address => (address ? address.substringAfter('tel:') : undefined);

const getToPhone = (conversation) => {
    let toPhone = addressToPhone(conversation.customer?.dnis);
    if (!toPhone) {
        toPhone = addressToPhone(conversation.address);
    }
    if (conversation.fromPhone === toPhone) {
        toPhone = undefined;
    }
    return toPhone;
};

const getFromPhone = (conversation) => {
    const { customer } = conversation;
    const phone = customer.address || customer.ani;
    return addressToPhone(phone);
};

const getParticipantIsOnCall = state => [
    ParticipantState.connected, 
    ParticipantState.alerting
].includes(state);

const getCustomerIncidentType = (customer) => {
    const attr = customer.attributes ? customer.attributes[INCIDENT_TYPE_ATTRIBUTE] : undefined;
    if (!attr) {
        return undefined;
    }
    return INCIDENT_TYPES.find(item => item.genesysProps.includes(attr))?.id;
};

const getLanguage = (customer) => {
    const selectedLang = customer.attributes?.SelectedLanguage;
    return LanguageOptions.find(item => item.label === (selectedLang || 'English'))?.value;
};

const transformConversation = (conversation) => {
    if (!conversation) {
        return null;
    }
    const meId = AuthService.me?.id;
    const queue = conversation.participants?.find(item => item.purpose === 'acd') || {};
    const customer = conversation.participants?.find(item => item.purpose === 'customer') || {};
    const agent = conversation.participants?.find(item => item.purpose === 'agent' && (item.userId === meId || item.user?.id === meId)) || {};
    
    customer.isOnCall = getParticipantIsOnCall(customer.state);
    agent.isOnCall = getParticipantIsOnCall(agent.state);
    
    const { id, address } = conversation;
    const result = { id, address }; 
    result.isConnected = true;
    result.customer = customer;
    result.agent = agent;
    result.queue = queue;
    result.incidentId = customer.attributes?.incidentId;
    result.isExistingIncident = customer.attributes?.Intent === 'Existing'; // in ['New', 'Existing']
    result.incidentTypeId = getCustomerIncidentType(customer);
    result.locationSearchCode = customer.attributes?.SearchCode;
    result.languageId = getLanguage(customer);
    result.fromPhone = conversation.fromPhone || getFromPhone(result);
    result.toPhone = conversation.toPhone || getToPhone(result);
    
    return result;
};

export { transformConversation };
