import './Tabs.scss';
import React from 'react';
import { Tabs } from 'library';
import { StarOutlined, PlusOutlined, PlusSquareFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import DesignatedTab from './Designated';
import ExternalTab from './External';
import InternalTab from './Internal';
import { DistanceFilter } from '../components';
import NewTab from './New';

const FacilityTabs = () => {
    const { useExternalFacilities } = useSelector(state => state.location);

    return (
        <Tabs
            className="tabs-choose-facility"
            type="card"
            tabBarExtraContent={(
                <DistanceFilter />
            )}
            size="middle"
            items={[
                {
                    key: '1',
                    label: 'Designated/Panel',
                    children: <DesignatedTab />,
                    icon: <StarOutlined />
                },
                useExternalFacilities && {
                    key: '2',
                    label: 'External',
                    children: <ExternalTab />,
                    icon: <PlusSquareFilled />
                },
                {
                    key: '3',
                    label: 'Internal',
                    children: <InternalTab />,
                },
                {
                    key: '4',
                    label: 'New',
                    children: <NewTab />,
                    icon: <PlusOutlined />
                },
            ]}
        />
    );
};

export default FacilityTabs;
