export const isChrome = () => 
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const isSafari = () => 
    navigator.userAgent.match(/AppleWebKit/);

export const isMobileSafari = () => 
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) 
    && isSafari();

export const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = () => 
    ('standalone' in window.navigator) && (window.navigator.standalone);

export const isIE11 = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('.NET ');
    return msie > 0;
};   

const toMatchMobile = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
];

export const isMobile = toMatchMobile.some(toMatchItem => navigator.userAgent.match(toMatchItem));

export const scrollTop = (top = 0, selector = undefined) => {
    const container = selector ? document.querySelector(selector) : window;
    container.scroll({
        top,
        left: 0,
        behavior: 'smooth'
    });
};

export const scrollBot = (bottom = 0, selector = 'body') => {
    const container = selector ? document.querySelector(selector) : window;
    container.scrollTo({
        top: container.scrollHeight - bottom,
        left: 0,
        behavior: 'smooth'
    });
};

export const scroll = (pixels = 0, selector = 'body', toBottom = false) => {
    if (toBottom) {
        scrollBot(pixels, selector);
    } else {
        scrollTop(pixels, selector);
    }
};

export const stopEvent = (e) => {
    if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
    }
};

export async function hardReload(url = window.location.href) {
    await fetch(url, {
        headers: {
            Pragma: 'no-cache',
            Expires: '-1',
            'Cache-Control': 'no-cache',
        },
    });
    window.location.href = url;
    // This is to ensure reload with url's having '#'
    window.location.reload();
}
