/* Uses E.164 is an international standard (ITU-T Recommendation), 
titled The international public telecommunication numbering plan */

import './InputPhone.scss';
import PropTypes from 'prop-types';
import PhoneInputWithCountry from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

const InputPhoneWithCountry = props => (
    <PhoneInputWithCountry
        defaultCountry="US"
        initialValueFormat="national"
        {...props}
    />
);

const InputPhone = ({ value, onPressEnter, ...rest }) => {
    let thisVal = value;
    if (thisVal && !thisVal.toString().startsWith('+1')) {
        thisVal = `+1${thisVal}`;
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && onPressEnter) {
            e.preventDefault();
            onPressEnter();
        }
    };
    return (
        <PhoneInput
            className="input-phone"
            country="US"
            value={thisVal}
            onKeyDown={onKeyDown}
            {...rest}
        />
    );
};

InputPhone.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPressEnter: PropTypes.func
};

export { InputPhoneWithCountry, InputPhone };
