import React from 'react';
import { useSelector } from 'react-redux';
import { LANGUAGES } from 'config';
import { formatPhone } from 'utils';

const IncidentSummaryGeneral = () => {
    const incident = useSelector(state => state.incident);
    const languageId = incident.fields?.claimant_language_code?.value;

    return (
        <>
            <h2>General Details</h2>
            <div className="data-grid">
                <div>Incident Id</div>
                <span>{incident.claimReferenceNumber || incident.id}</span>
                {incident.relatedIncident && (
                    <>
                        <div>Related Incident Id</div>
                        <span>{incident.relatedIncident.claimReferenceNumber}</span>
                    </>
                )}
                <div>Incident type</div>
                <span>{incident.$incidentType?.title}</span>
                <div>Caller Name</div>
                <span>
                    {incident.fields?.caller_first_name?.value}
                    {' '}
                    {incident.fields?.caller_last_name?.value}
                </span>
                <div>Callback Number</div>
                <span>{formatPhone(incident.fields?.caller_phone?.value)}</span>
                <div>Claimant Name</div>
                <span>
                    {incident.fields?.claimant_first_name?.value}
                    {' '}
                    {incident.fields?.claimant_last_name?.value}
                </span>
                <div>Claimant Age</div>
                <span>{incident.fields?.claimant_age?.value}</span>
                <div>Language</div>
                <span>{LANGUAGES[languageId]}</span>
                <div>Facility</div>
                <span>{incident?.facility?.name}</span>
                {incident.thirdParty?.contact?.rklNodeCode ? (
                    <>
                        <div>Randstad BU Number</div>
                        <span>{incident.thirdParty?.contact?.rklNodeCode}</span>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default IncidentSummaryGeneral;
