import './Table.scss';
import React, { useState, useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Table as BaseTable } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { local } from 'services';
import { useConstructor } from 'hooks';
import { catchError } from 'utils';
import { renderFn } from './config';
import { getFiltersProps } from './filters';
import Context from './context';

const indicator = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const SpinProps = { indicator };

const FetchParamsDefault = { page: 1, filters: {}, sort: {}, take: 50 };
const TableParamsStorage = {};
const CachedParams = {
    get: name => TableParamsStorage[name] || FetchParamsDefault,
    set: (name, params) => TableParamsStorage[name] = { ...params }
};

const UserPrefs = {
    get: name => local.custom(name, true)() || {},
    set: (name, value) => local.custom(name, true)(value)
};

const DefaultPaginationProps = {
    simple: true,
    // showTotal: total => <><b>{total}</b> items</>,
    showSizeChanger: true,
    hideOnSinglePage: false,
    size: 'small',
    position: ['bottomLeft'],
    showLessItems: false,
};

const Table = React.forwardRef(({
    name, 
    fetchAction, 
    rowKey = 'id',
    className = '',
    rowClassName = () => { },
    persistentFilters = {},
    onTotalChange = () => { },
    defaultPageSize = 50,
    columns: defaultColumns,
    scroll = { x: 'max-content' },
    pageSizeOptions = ['50', '100', '200'],
    cache = false,
    loadOnInit = true,
    ...rest
}, ref) => {
    const filterRef = React.useRef();
   
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [columns, setColumns] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchParams, setFetchParams] = useState(FetchParamsDefault);

    const fetch = useCallback((parameters, skipLoader) => {
        let params = { ...parameters };
        if (cache) {
            if (Object.isEmpty(params)) {
                params = CachedParams.get(name);
            }
            CachedParams.set(name, params);
        }
         
        if (!skipLoader) {
            setLoading(SpinProps);
        }

        const { page, take, filters, sort } = params;
        const $filters = {
            ...persistentFilters,
            ...filters
        };

        return fetchAction({ page, take, filters: $filters, sort })
            .then((res) => {
                if (res) {
                    const { total } = res;
                    setPagination(oldPagination => ({
                        ...oldPagination,
                        current: page,
                        total,
                        pageSize: take
                    }));
                    setData(res.data);
                    setFetchParams({
                        page, take, filters, sort, total
                    });
                    if (onTotalChange) {
                        onTotalChange(total);
                    }
                }
            })
            .catch(catchError)
            .finally(() => {
                setLoading(false);
            });
    }, [fetchAction, name, cache, onTotalChange, persistentFilters]);

    const updateUserPrefs = useCallback((cols) => {
        const dataToSave = {
            pageSize: pagination.pageSize,
            cols: {}
        };

        cols.forEach(({ dataIndex, width, hide }) => {
            if (dataIndex) {
                dataToSave.cols[dataIndex] = {
                    width,
                    hide
                };
            }
        });
        UserPrefs.set(name, dataToSave);
    }, [name, pagination.pageSize]);

    useConstructor(() => {
        const userPrefs = UserPrefs.get(name) || { cols: {} };
        const savedFetchParams = CachedParams.get(name);
        
        const nextColumns = defaultColumns
            .map((column, index) => {
                let { key } = column;
                const { title, dataIndex, className: columnClassName } = column;
                key = key || dataIndex;

                const filterProps = getFiltersProps(column, filterRef);
                const display = column.display || title;

                const hasPrefs = userPrefs.cols && userPrefs.cols[key];
                const width = hasPrefs ? (userPrefs.cols[key].width || column.width) : column.width;
                const hide = column.hidden || (hasPrefs ? userPrefs.cols[key].hide : !!column.hide);

                // Filter
                const savedFilter = savedFetchParams.filters[key];
                const filteredValue = savedFilter || column.defaultFilteredValue || null;

                // Sort
                const savedSortOrder = savedFetchParams.sort.field === key
                    ? savedFetchParams.sort.order : undefined;
                const sortOrder = savedSortOrder || column.defaultSortOrder;

                return {
                    key,
                    dataIndex: key,
                    ...column,
                    ...filterProps,
                    width,
                    hide,
                    display, // for settings
                    title,
                    sorter: !!column.sort,
                    sortBy: column.sortBy || key,
                    render: column.render || renderFn[column.type] || null,
                    sortOrder,
                    filteredValue,
                    className: columnClassName || '',
                    onHeaderCell: ({ width: newWidth }) => ({
                        width: newWidth,
                        index,
                    }),
                };
            });
        setColumns(nextColumns);

        // Page
        const pageSize = savedFetchParams.take || userPrefs.pageSize || defaultPageSize;
        const defaultPage = savedFetchParams.page || 1;

        // Filters
        const defaultFilters = { ...savedFetchParams.filters } || {};
        defaultColumns.filter(col => col.defaultFilteredValue)
            .forEach((col) => {
                defaultFilters[col.key] = col.defaultFilteredValue;
            });

        // Sort
        const defaultSortColumn = nextColumns.find(col => col.defaultSortOrder);
        const defaultSort = {
            field: defaultSortColumn?.sortBy || 'id',
            order: defaultSortColumn?.defaultSortOrder || 'asc'
        };

        setPagination({
            ...DefaultPaginationProps,
            pageSizeOptions,
            defaultPageSize: savedFetchParams.take || pageSize,
            defaultCurrent: defaultPage,
        });

        const defaultParams = {
            page: defaultPage,
            take: defaultPageSize,
            sort: defaultSort,
            filters: defaultFilters,
        };
        if (loadOnInit) {
            fetch(defaultParams);
        } else {
            setFetchParams(defaultParams);
        }
    }, [name, defaultColumns, defaultPageSize, fetch]);

    const removeRow = useCallback((row) => {
        const nextData = data.filter(item => item.id !== row.id);
        setData(nextData);
    }, [data]);

    const fetchRow = useCallback(async (prop, propName = rowKey) => {
        const changeRowLoading = (idx, $loading) => {
            const row = data[idx];
            const nextData = Array.changeItem(data, { ...row, $loading });
            setData(nextData);
        };

        const idx = data.findIndex(i => i[propName] === prop);
        if (idx < 0) {
            fetch();
            return;
        }

        changeRowLoading(idx, true);

        const { data: updatedRow } = await fetchAction({
            take: 1,
            page: 1,
            filters: { [propName]: prop }
        })
            .catch(catchError)
            .finally(() => changeRowLoading(idx, false));

        if (updatedRow && updatedRow.length) {
            setData((oldData) => {
                const nextData = Array.changeItem(oldData, updatedRow[0]);
                return nextData;
            });
        }
    }, [data, fetch, fetchAction, rowKey]);

    const clearFilters = useCallback(() => {
        const nextColumns = columns.map(column => ({
            ...column,
            filteredValue: null,
        }));
        setColumns(nextColumns);
        fetch({ ...fetchParams, filters: {} });
    }, [columns, fetch, fetchParams]);

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination);
        let sortByField = '';

        const nextColumns = columns.map((column) => {
            const isSort = sorter.field === column.key;
            if (isSort) {
                sortByField = column.sortBy;
            }
            return {
                ...column,
                filteredValue: filters[column.key],
                sortOrder: sorter.field === column.key && sorter.order,
            };
        });

        setColumns(nextColumns);
        const params = {
            take: newPagination.pageSize,
            page: newPagination.current,
            sort: {
                field: sortByField || sorter.field,
                order: sorter.order
            },
            filters,
        };
        fetch(params);
    };

    const updateEditMode = useCallback((value) => {
        const nextColumns = [...columns];
        nextColumns[0].hide = value;
        setColumns(nextColumns);
        setEditMode(value);
        if (!value) {
            setSelected([]);
        }
    }, [columns]);

    const filteredColumns = columns.filter(i => !i.hide);

    const contextValue = React.useMemo(
        () => ({
            columns,
            setColumns,
            fetch,
            fetchRow,
            fetchParams,
            fetchAction,
            clearFilters,
            removeRow,
            editMode,
            updateEditMode,
            selected,
            loading: !!loading,
            updateUserPrefs,
            data,
            pageSize: pagination.pageSize,
            persistentFilters
        }),
        [
            clearFilters, columns, editMode,
            fetch, fetchAction, fetchParams,
            fetchRow, loading, removeRow,
            selected, updateEditMode, updateUserPrefs,
            data, pagination.pageSize,
            persistentFilters
        ]
    );

    useImperativeHandle(ref, () => ({
        fetch,
        fetchRow,
        fetchParams
    }));

    return (
        <Context.Provider
            value={contextValue}
        >
            <BaseTable
                key={name}
                className={`table ${className}`}
                loading={loading}
                rowKey={rowKey}
                dataSource={data}
                columns={filteredColumns}
                pagination={pagination}
                onChange={handleTableChange}
                showSorterTooltip={false}
                size="small"
                scroll={scroll}
                rowClassName={item => (item.$loading ? 'loading' : rowClassName(item))}
                {...rest}
            />
        </Context.Provider>
    );
});

Table.propTypes = {
    name: PropTypes.string.isRequired,
    rowKey: PropTypes.string,
    fetchAction: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    rowSelection: PropTypes.object,
    /* @TODONEXT column props
    title: 'Location',
    key: 'locationId',
    hide: true/false, - defaultHide, switchable via table settings
    hidden: true/false  - always hidden
    type: ColumnTypes,
    width: 160,
    ellipsis: true,
    render: (value, row) => row.title,
    filterService: LocationService.options,
    filter: true,
    sort: 'title',
    className: ''
    */
    onTotalChange: PropTypes.func,
    rowClassName: PropTypes.func,
    persistentFilters: PropTypes.object,
    defaultPageSize: PropTypes.number,
    scroll: PropTypes.object,
    className: PropTypes.string,
    pageSizeOptions: PropTypes.array,
    onRow: PropTypes.func,
    cache: PropTypes.bool,
    loadOnInit: PropTypes.bool
};

export { Table, BaseTable };
