import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, notification } from 'library';
import { INCIDENT_STATE } from 'config';
import IncidentContext from '../../Incident/context';

const IntakeReporOnlySummaryFooter = () => {
    const navigate = useNavigate();
    const { $updating, $loading } = useSelector(state => state.incident);
    const { done, onDoneSuccess, update } = useContext(IncidentContext);
    const form = Form.useFormInstance();

    const onReportOnlyCancel = () => navigate(-1);

    const onReportOnlyFinish = () => {
        const values = form.getFieldsValue();
        update({
            ...values,
            incidentState: INCIDENT_STATE.DONE
        })
            .then(onDoneSuccess);
    };

    useEffect(() => {
        notification.info({
            message: 'Review incident details if needed, and click “Finish” to complete or “Cancel”',
            duration: 5
        });
    }, []);

    if (done) {
        return null;
    }

    return (
        <footer>
            <Button
                onClick={onReportOnlyCancel}
                type="link"
            >
                Cancel
            </Button>
            <Button
                type="primary"
                onClick={onReportOnlyFinish}
                disabled={$loading}
                loading={!!$updating}
            > 
                Finish
            </Button>
        </footer>
    );
};

export default IntakeReporOnlySummaryFooter;
