import styles from './styles/variables.module.scss';
import { Empty } from '../library';

const ProviderProps = {
    theme: {
        token: {
            borderRadius: parseInt(styles.borderRadius, 10),
            colorBgBase: styles.colorBgBase,
            colorError: styles.colorError,
            colorInfo: styles.colorInfo,
            colorPrimary: styles.colorPrimary,
            colorSuccess: styles.colorSuccess,
            colorTextBase: styles.colorTextBase,
            colorWarning: styles.colorWarning,
            controlHeight: parseInt(styles.controlHeight, 10),
            fontFamily: styles.fontFamily,
            fontSize: parseInt(styles.fontSize, 10),
            sizePopupArrow: parseInt(styles.sizePopupArrow, 10),
            sizeStep: parseInt(styles.sizeStep, 10),
            sizeUnit: parseInt(styles.sizeUnit, 10),
            colorBorder: styles.colorBorder,
        },
        
    },
    renderEmpty: () => <Empty />,
    prefixCls: 'lx',
};

export default ProviderProps;
