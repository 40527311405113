import { formatDate, formatSsn } from 'utils';
import { INCIDENT_TYPE_WC } from 'config';

export const IncidentProps = [
    {
        key: 'incidentId',
        label: 'Incident Id',
        render: item => <u> {item.claimReferenceNumber || item.id} </u>
    },
    {
        key: 'createdDate',
        label: 'Date Created',
        render: item => formatDate(item.createdDate)
    },
    {
        key: 'incidentTypeId',
        label: 'Incident Type',
        filter: 'incidentTypeId',
        render: item => item.$incidentType?.title
    },
    {
        key: 'injuryDate',
        label: 'Incident Date',
        render: item => formatDate(item.injuryDate)
    },
    
    {
        key: 'locationId',
        label: 'Employer',
        filter: 'locationId',
        render: item => item.employerLocationName
    },
    {
        key: 'claimantFirstLastName',
        label: 'Claimant',
        filter: 'claimantFirstLastName',
    },
    {
        key: 'claimantSsn',
        label: 'SSN',
        filter: 'claimantSsn',
        render: item => formatSsn(item.claimantSsn)
    },
    {
        key: 'claimantBirthDate',
        label: 'Date of Birth',
        filter: 'claimantBirthDate',
        render: item => formatDate(item.claimantBirthDate)
    },
    {
        key: 'mainComplaintTitle',
        label: 'Injury Type',
        incidentTypes: [INCIDENT_TYPE_WC],
    },
    {
        key: 'injuryPartOfBodyDescription',
        label: 'Part of Body (Description)',
        incidentTypes: [INCIDENT_TYPE_WC],
    },
    {
        key: 'injuryLeftRight',
        label: 'Part of Body (Left or Right)',
        incidentTypes: [INCIDENT_TYPE_WC],
    }
];
