import './ButtonCopy.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Form } from 'library';

const ButtonCopy = ({ disabled = false, config: { label, populate } }) => {
    const form = Form.useFormInstance();
    
    const onChange = (val) => {
        if (val && populate?.length) {
            populate.forEach(({ from, to }) => {
                const value = form.getFieldValue(['fields', from]);
                form.setFieldValue(['fields', to], value);
            });
        }
    };

    return (
        <div className="form-item-copy">
            <label htmlFor="switch"> {label}</label>
            <Switch 
                onChange={onChange} 
                size="small" 
                disabled={disabled}
            />
        </div>
    );
};

ButtonCopy.propTypes = {
    config: PropTypes.object.isRequired, // @TODONEXT shape it
    disabled: PropTypes.bool
};

export default ButtonCopy;
