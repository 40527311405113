/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'library';
import { GnAuthService } from 'services';

const initialState = {
    loading: false,
};

const signIn = createAsyncThunk(
    'me/signIn',
    () => GnAuthService.signIn()
);

const meSlice = createSlice({
    name: 'me',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => 
                action.payload
            )
            .addCase(signIn.rejected, (state, { error }) => {
                message.error(error.message);
                state.loading = false;
            });
    }
});

export {
    signIn
};

export default meSlice.reducer;
