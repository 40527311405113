import PropTypes from 'prop-types';
import { Button } from 'antd';

const Confirm = ({
    value, onChange,
    disabled = false, className = '' }) => (
        <Button
            block
            ghost
            type="primary"
            size="small"
            className={`btn-confirm ${className}`}
            onClick={() => onChange('CONFIRMED')}
            disabled={!!value || disabled}
        > {value ? 'Confirmed' : 'Confirm'}
        </Button>
);

Confirm.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

export { Confirm };
