import './DistanceFilter.scss';
import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Space, Button, AutoCompleteBase, Spin, Tag } from 'library';
import { SearchOutlined } from '@ant-design/icons';
import FacilityContext from '../../context';

const AddressFiledOptions = {
    injury_address: {
        label: 'Incident Address',
        fields: ['injury_address', 'injury_city'],
    },
    employer_location_address: {
        label: 'Employer Location Address',
        fields: ['employer_location_address', 'employer_location_city'],
    },
    claimant_address: {
        label: 'Claimant Address',
        fields: ['claimant_address', 'claimant_city'],
    },
};

const DistanceFilter = () => {
    const { setAddress } = useContext(FacilityContext);
    const incident = useSelector(state => state.incident);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (incident?.fields) {
            const res = Object.keys(AddressFiledOptions)
                .filter(name => !!incident.fields[name]?.value)
                .map((name) => {
                    const config = AddressFiledOptions[name];
                    const addr = config.fields
                        .map(field => incident.fields[field]?.value?.toString())
                        .filter(val => !!val)
                        .join(', ');
                    
                    return {
                        value: addr.toString(),
                        type: config.label,
                        label: (
                            <div>
                                {addr}
                                {' '}
                                <Tag>
                                    {config.label} 
                                </Tag>
                            </div>
                        )
                    };
                });
            setOptions(res);
            if (res.length) {
                const defaultAddress = res[0].value;
                setValue(defaultAddress);
                setAddress(defaultAddress);
            }
        }
    }, [incident, setAddress]);

    const onSearchClick = () => {
        setAddress(value);
    };

    const addressLabel = options.find(item => item.value === value)?.type || '';

    return (
        <Spin spinning={!!incident.$loading}>
            <div className="distance-filter-label">
                Measure Facility Distance From:
                {' '}
                {addressLabel && <Tag>{addressLabel}</Tag>}
            </div>

            <Space.Compact block className="distance-filter">
                <AutoCompleteBase
                    value={value}
                    onChange={setValue}
                    popupMatchSelectWidth={false}
                    allowClear
                    options={options}
                    placeholder="Required"
                    onKeyDown={({ keyCode }) => (keyCode === 13 ? onSearchClick() : null)}
                />
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={onSearchClick}
                    disabled={!value}
                />
            </Space.Compact>
        </Spin>
    );
};

export { DistanceFilter };
