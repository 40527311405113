import { FacilityCard } from 'components/Facility';
import { useSelector } from 'react-redux';

const FacilitySummary = () => {
    const { facility } = useSelector(state => state.incident);

    if (!facility) {
        return null;
    }

    return (
        <>
            <h2>Facility</h2>
            <FacilityCard id={facility.id} />
        </>

    );
};

export default FacilitySummary;
