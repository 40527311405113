import './InputTime.scss';
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const Formats = {
    display: 'hh:mm A',
    db: 'HH:mm'
};

const InputTime = ({
    value,
    onChange,
    className = '',
    allowClear = true,
    ...rest
}) => {
    const ref = React.useRef();
    const [thisVal, setThisVal] = useState();

    const toTime = (val) => {
        if (val.length > 4) {
            const time = dayjs(val, Formats.db);
            if (time.isValid()) {
                return time;
            }
        }
        return undefined;
    };   
    useEffect(() => {
        if (value) {
            const time = dayjs(value, Formats.db);
            const formatted = time.format(Formats.display);
            setThisVal(formatted);
            const correctValue = time.format(Formats.db);
            if (value !== correctValue && onChange) {
                onChange(correctValue);
            }
        } else setThisVal();
    }, [value, onChange]);
    
    const applyValue = (val) => {
        setThisVal(val);
        if (val) {
            const isValidTime = toTime(val);
            if (isValidTime) {
                onChange(val);
            }
        } else {
            onChange(val);
        }
    };

    const onKeyDown = (e) => {
        const keysToSwitchAP = ['a', 'p', 'arrowup', 'arrowdown'];
        const key = e.key.toLowerCase();
        if (keysToSwitchAP.includes(key)) {
            let time = toTime(thisVal);
            if (time) {
                const prefix = time.format('A').substring(0, 1).toLowerCase();
                const multiply = prefix === 'p' ? -1 : 1;
                time = time.add(multiply * 12, 'hour');
                applyValue(time.format(Formats.db));
            }
        }
    };

    const onBeforeChange = (e) => {
        let val = e.target.value;
        val = val.replace(/[^0-9$:]/g, '');
        const last = parseInt(val.slice(-1), 10);
        const { length } = val;

        if (Number.isNaN(last)) {
            if (length === 2) {
                val = `0${val}`;
            } else if (length !== 3) {
                val = val.slice(0, -1);
            }
        } else {
            switch (length) {
                case 1: {
                    if (last > 2) {
                        val = `0${val}:`;
                    }
                    break;
                }
                case 2: {
                    val = `${val}:`;
                    break;
                }
                case 4: {
                    if (!Array.create(6).includes(last)) {
                        val = val.slice(0, -1);
                    }
                    break;
                }
                default:
            }
        }
                    
        applyValue(val);
    };

    const formatIt = (val) => {
        if (val?.length === 5) {
            return dayjs(val, Formats.db).format(Formats.display);
        }
        return thisVal;
    };

    return (
        <Input
            ref={ref}
            type="text"
            value={formatIt(thisVal)}
            onKeyDown={onKeyDown}
            onChange={onBeforeChange}
            className={`input-time ${className}`}
            maxLength={8}
            suffix={<ClockCircleOutlined />}
            allowClear={allowClear}
            {...rest}
        />
    );
};

InputTime.propTypes = {
    allowClear: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string
};

export { InputTime };
