import { Input, Select } from 'library';
import { USStateOptions } from 'config/states';
import { FacilityTypeOptions } from 'config';

export default [
    {
        label: 'Facility Name',
        name: 'name',
        required: true,
        component: Input
    },
    {
        label: 'Facility Type',
        name: 'type',
        required: true,
        component: Select, 
        options: FacilityTypeOptions
    },
    {
        label: 'Address',
        name: 'address',
        component: Input
    },
    {
        label: 'Address 2',
        name: 'address2',
        component: Input
    },
    {
        label: 'City',
        name: 'city',
        component: Input
    },
    {
        label: 'State',
        name: 'state',
        component: Select,
        options: USStateOptions,
    },
    {
        label: 'Zip',
        name: 'zip',
        component: Input
    },
    {
        label: 'Phone',
        name: 'phone',
        component: Input
    },
    {
        label: 'Fax',
        name: 'fax',
        component: Input
    },
    {
        label: 'Email',
        name: 'email',
        component: Input
    },
];
