import './FormNavigation.scss';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Steps } from 'library';
import { debounce } from 'utils';

const FormNavigation = ({ categories }) => {
    const [current, setCurrent] = useState(0);
     
    const navigationItems = categories.map(category => ({
        title: <a title={`${category.title}`} href={`#${category.name}`}> {category.title} </a>,
        status: 'wait',
    }));

    useEffect(() => {
        const OFFSET = -40;
        const scrollPanel = document;
        const onScroll = () => {
            const activeElements = [...document.querySelectorAll('.form-category-header .scroll-to-fieldset')]
                .map((element, index) => ({
                    index, 
                    y: element.getBoundingClientRect().y,
                    id: element.id
                }))
                .filter(element => element.y >= OFFSET);
            if (activeElements.length) {
                if (activeElements[0]?.y < (window.innerHeight - 150)) {
                    setCurrent(activeElements[0]?.index);
                }
            }
        };

        const debouncedOnScroll = debounce(onScroll, 50);
        scrollPanel.addEventListener('scroll', debouncedOnScroll); 
        return () => {
            scrollPanel.removeEventListener('scroll', debouncedOnScroll);
        };
    }, []);

    return (
        <Steps
            className="form-navigation-steps"
            direction="horizontal"
            labelPlacement="horizontal"
            progressDot={false}
            responsive
            current={current}
            size="small"
            type="default"
            items={navigationItems}
        />
    );
};

FormNavigation.propTypes = {
    categories: PropTypes.array.isRequired
};

export default FormNavigation; 
