import './ReportOnlyReason.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'library';
import { InjuryOptions } from './config';

const ReportOnlyReasonSelect = ({ value, onChange }) => {
    const onBeforeChange = (val) => {
        const item = InjuryOptions.find(it => it.value === val);

        const reportOnlyReason = {
            title: `${item.label}. ${item.description}`,
            value: item.value
        };
        onChange(reportOnlyReason);
    };

    return (
        <Select
            options={InjuryOptions}
            className="select-report-only-reason"
            // eslint-disable-next-line react/no-unstable-nested-components
            optionRender={({ data }) => (
                <>
                    <b>{data.label}</b>
                    <div>{data.description}</div>
                </>
            )}
            value={value}
            onChange={onBeforeChange}
            autoFocus
            listHeight={600}
            placeholder="Required"
            popupClassName="popup-report-only-reason"
        />
    );
};

ReportOnlyReasonSelect.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object
};

export default ReportOnlyReasonSelect;
