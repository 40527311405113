import './Incident.scss';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIncident, putIncident, patchIncident, clear as clearIncident } from 'store/incident';
import { getLocation, clear as clearLocation } from 'store/location';
import { notification } from 'library';
import { catchError } from 'utils';
import { INCIDENT_STATE } from 'config';
import { clear as clearConversation } from 'store/call';
import Context from './context';

const IncidentRoute = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { id: paramId } = useParams();
    const {
        id: incidentId,
        relatedIncident,
        $incidentType,
        incidentState,
        locationId
    } = useSelector(state => state.incident);
    const [done, setDone] = useState(false);
    const patchPromise = useRef();
    const putPromise = useRef();
   
    useEffect(() => {
        if (paramId) {
            dispatch(clearIncident());
            dispatch(getIncident(paramId))
                .unwrap()
                .catch(catchError);
        }
    }, [dispatch, paramId]);

    useEffect(() => {
        if (locationId) {
            dispatch(getLocation({ id: locationId }));
        }
    }, [locationId, dispatch]);

    const update = useCallback(async (values) => {
        if (!!patchPromise.current) {
            await patchPromise.current;
        }
        if (!!putPromise.current) {
            await putPromise.current;
        }
        putPromise.current = dispatch(putIncident({
            id: incidentId,
            ...values
        }))
            .unwrap()
            .finally(() => putPromise.current = undefined);

        return putPromise.current;
    }, [dispatch, incidentId]);

    const patch = useCallback(async (data) => {
        if (!incidentId || putPromise.current) {
            return Promise.resolve();
        }
        if (!!patchPromise.current) {
            return patchPromise.current;
        }
        patchPromise.current = dispatch(patchIncident({
            id: incidentId,
            patchEvent: true,
            ...data,
        }))
            .unwrap()
            .finally(() => patchPromise.current = undefined);

        return patchPromise.current;
    }, [dispatch, incidentId]);

    const cleanUp = () => {
        dispatch(clearConversation());
        dispatch(clearLocation());
        dispatch(clearIncident());
    };

    const onDoneSuccess = async () => {
        if (relatedIncident && relatedIncident.incidentState === INCIDENT_STATE.INTAKE) {
            notification.success({
                message: `${$incidentType.abbr} incident has been updated.`,
                description: `Please continue with ${relatedIncident.$incidentType.abbr} referral.`
            });
            navigate(`/incidents/${relatedIncident.id}/intake`);
        } else {
            setDone(true);
        }
    };

    const PreviewPath = `/incidents/${incidentId}/preview`;
    
    useEffect(() => {
        if (incidentId
            && incidentState === INCIDENT_STATE.DONE 
            && !relatedIncident 
            && pathname !== PreviewPath) {
            navigate(PreviewPath);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, incidentId]);

    const onVoidSuccess = () => onDoneSuccess();

    const onTriageSuccess = () => {
        setDone(true);
    };

    return (
        <Context.Provider
            value={{
                update,
                patch,
                onVoidSuccess,
                onTriageSuccess,
                onDoneSuccess,
                cleanUp,
                done,
                incidentId
            }}
        >
            <div className="route route-incident">
                <Outlet />
            </div>
        </Context.Provider>
    );
};

export default IncidentRoute;
