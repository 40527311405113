import React, { useContext, useState } from 'react';
import { ButtonWithConfirm } from 'library';
import { INCIDENT_STATE } from 'config';
import IncidentContext from 'routes/Incident/context';

const IntakeMarkVoidButton = () => {
    const { incidentId, update, onVoidSuccess } = useContext(IncidentContext);
    const [voiding, setVoiding] = useState(false);

    const onVoidClick = () => {
        setVoiding(true);
        update({ incidentState: INCIDENT_STATE.VOID })
            .then(onVoidSuccess)
            .finally(() => setVoiding(false));
    };

    return (
        <ButtonWithConfirm
            okText="Yes"
            cancelText="No"
            buttonProps={{ disabled: !incidentId, loading: voiding }}
            type="link"
            onConfirm={onVoidClick}
            description="Are you sure you want to mark this Incident as Void?"
        >
            Mark as Void
        </ButtonWithConfirm>
    );
};

export default IntakeMarkVoidButton;
