import { formatSsn, formatDate, } from 'utils';
import { USStateOptions } from 'config';

export default {
    source: 'Enspiri API',
    sourceType: 'contact',
    title: 'Lintelio',
    className: 'w1000 info',
    description: 'The chosen location gives you the option to search our database of known claimants to speed up the intake process.',
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            source: 'lastName',
            target: 'claimant_last_name',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            source: 'firstName',
            target: 'claimant_first_name',
            filter: 'firstName',
            width: 120,
        },
        {
            label: 'SSN',
            source: 'ssn',
            target: 'claimant_ssn',
            filter: 'ssn',
            filterType: 'ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            source: 'dob',
            target: 'claimant_birth_date',
            filter: 'dob',
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            source: 'employeeId',
            target: 'claimant_employee_id',
            filter: 'employeeId',
            width: 100
        },
        {
            label: 'Occupation',
            source: 'occupation',
            target: 'employment_job_title_or_occupation',
            width: 120
        },
        {
            label: 'Hire Date',
            source: 'hireDate',
            target: 'employment_hired_date',
            render: formatDate,
            width: 120
        },
        {
            label: 'Email',
            source: 'email',
            target: 'claimant_email_address',
            width: 120
        },
        {
            label: 'Home Phone',
            source: 'homePhone',
            target: 'claimant_home_phone',
            width: 150
        },
        {
            label: 'Mobile Phone',
            source: 'mobilePhone',
            target: 'claimant_mobile_phone',
            width: 150
        },
        {
            label: 'Work Phone',
            source: 'workPhone',
            target: 'claimant_work_phone',
            width: 150
        },
        {
            label: 'Gender',
            source: 'gender',
            target: 'claimant_gender_code',
            targetFn: (_, row) => row.genderCode?.toString(),
            width: 80
        },
        {
            label: 'Marital Status',
            source: 'maritalStatus',
            target: 'claimant_marital_status_code',
            targetFn: (_, row) => row.maritalStatusCode?.toString(),
            width: 80
        },
        {
            label: 'Address',
            source: 'address1',
            target: 'claimant_address',
            width: 120
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 100
        },
        {
            label: 'State',
            target: 'claimant_state',
            source: 'state',
            options: USStateOptions,
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
    ]
};
