import { StarOutlined } from '@ant-design/icons';
import { formatPhone } from 'utils';
import { FacilityTypeOptions, USStateOptions } from 'config';
import { FacilityServices, Notes } from 'components/Facility';
import {
    renderFacilityDistance,
    FilterFacilityNetwork,
    renderType,
    renderName,
    renderOpenHours,
} from '../../components';

export default ({ preferredIds, locationId }) => ([
    {
        title: '',
        dataIndex: 'id',
        width: 20,
        fixed: true,
        render: value => (preferredIds.includes(value) && <StarOutlined />),
    },
    {
        title: 'ER',
        dataIndex: 'type',
        width: 60,
        filter: FacilityTypeOptions,
        sort: true,
        fixed: true,
        render: renderType,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        width: 200,
        filter: true,
        sort: true,
        fixed: true,
        render: renderName
    },
    {
        title: 'Network',
        dataIndex: 'networkId',
        width: 150,
        filter: args => <FilterFacilityNetwork {...args} locationId={locationId} />,
        render: (_, row) => row.networkName,
    },
    {
        title: 'Proximity',
        dataIndex: 'distance',
        width: 120,
        render: renderFacilityDistance,
        sort: true,
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Code',
        dataIndex: 'code',
        width: 80,
        filter: true,
        sort: true,
    }, 
    
    {
        title: 'Open Hours',
        dataIndex: 'openHours',
        width: 100,
        render: renderOpenHours
    },
    {
        title: 'Address',
        dataIndex: 'address',
        filter: true,
        width: 200,
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        width: 100,
        render: formatPhone
    },
    {
        title: 'Fax',
        dataIndex: 'fax',
        width: 100,
        render: formatPhone
    },
    {
        title: 'City',
        dataIndex: 'city',
        width: 100,
        filter: true,
    }, 
    {
        title: 'State',
        dataIndex: 'state',
        width: 100,
        filter: USStateOptions,
        isFilterSingleSelect: true
    },
    {
        title: 'Zip',
        dataIndex: 'zip',
        width: 100,
        filter: true,
    },
    {
        title: 'Notes',
        dataIndex: 'referralNotes',
        width: 300,
        render: value => <Notes text={value} />
    },
    {
        title: 'Services',
        dataIndex: 'services',
        fixed: 'right',
        width: 65,
        render: (_, item) => <FacilityServices item={item} />
    },
    
]);
