import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatPhone } from 'utils';
import { useService } from 'hooks';
import { Modal, Radio, Spin } from 'library';
import { useDispatch } from 'react-redux';
import { GnContactService, GnQueueService } from 'services';
import { inviteExternal, inviteInternal, transferToInternalInterpreter } from 'store/call';
import { QUEUE_ID_INTERPRETERS, ROUTING_STATUS } from 'config';

const INTERNAL_QUEUE_KEY = 'internal';

const InviteInterpreterModal = ({ setOpen, setInterpreter }) => {
    const [loadContacts, { data: contacts }] = useService(GnContactService.search, []);
    const [memberStatuses, setMemberStatuses] = useState({ all: 'N/A', idle: 'N/A' });
    const [inviting, setInviting] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [value, setValue] = useState(INTERNAL_QUEUE_KEY);
   
    useEffect(() => {
        loadContacts({
            q: 'interpreter',
            expand: 'externalOrganization'
        });
    }, [loadContacts]);

    useEffect(() => {
        setLoading(true);
        GnQueueService.getMembers(QUEUE_ID_INTERPRETERS)
            .then((members) => {
                const idle = members
                    .filter(item => item.routingStatus === ROUTING_STATUS.IDLE);
                setMemberStatuses({
                    all: members.length,
                    idle: idle.length
                });
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (memberStatuses.idle === 0 && contacts.length) {
            setValue(contacts[0].phone);
        }
    }, [memberStatuses.idle, contacts]);

    const callTransferToInternal = () => dispatch(transferToInternalInterpreter(value))
        .unwrap();

    const callInviteExternal = () => dispatch(inviteExternal(value))
        .unwrap()
        .then(() => {
            if (setInterpreter) {
                setInterpreter(value);
            }
        });
    
    const callInviteInternal = () => dispatch(inviteInternal(value))
        .unwrap();

    const onOk = () => {
        const promise = () => {
            switch (value) {
                case INTERNAL_QUEUE_KEY:
                    return callTransferToInternal;
                
                case QUEUE_ID_INTERPRETERS:
                    return callInviteInternal;
                
                default:
                    return callInviteExternal;
            }
        };

        setInviting(true);
        promise()()
            .then(() => setOpen(false))
            .finally(() => setInviting(false));
    };

    return (
        <Modal
            className="success modal-choose-to-continue modal-interpreters"
            title="Interpreter Options"
            open
            confirmLoading={inviting}
            onOk={onOk}
            onCancel={() => setOpen(false)}
            cancelButtonProps={{ type: 'text' }}
            closable={false}
            centered
        >
            <div className="content">
                <Radio.Group
                    onChange={e => setValue(e.target.value)}
                    value={value}
                >
                    <h2>Internal Interpreters</h2>
                    <Radio
                        value={INTERNAL_QUEUE_KEY}
                        className={`${value === INTERNAL_QUEUE_KEY ? 'active' : ''
                            } internal available-${memberStatuses.idle}`}
                    >
                        <div>
                            <span>Transfer to Internal Interpreter Queue</span>
                            <div>
                                Agents Available:{' '}
                                <b>{memberStatuses.idle}</b>
                                {' '}/{' '}{memberStatuses.all}
                            </div>
                        </div>
                    </Radio>
                    <Radio
                        value={QUEUE_ID_INTERPRETERS}
                        className={`${value === QUEUE_ID_INTERPRETERS ? 'active' : ''
                            } internal available-${memberStatuses.idle}`}
                    >
                        <div>
                            <span>Invite from Internal Interpreter Queue</span>
                        </div>
                    </Radio>
                    <h2>Invite External</h2>
                    <Spin spinning={loading}>
                        {contacts.map(item => (
                            <Radio
                                key={item.id}
                                value={item.phone}
                                className={value === item.phone ? 'active' : ''}
                            >
                                <div>
                                    <span>
                                        {item.firstName}
                                        {' '}
                                        {item.lastName}
                                    </span>
                                    <div>
                                        {item.title}
                                    </div>
                                </div>
                                <div>
                                    {formatPhone(item.phone)}
                                    <div>{item.phoneType}</div>
                                </div>
                            </Radio>
                    ))}
                    </Spin>
                </Radio.Group>
            </div>
        </Modal>
    );
};

InviteInterpreterModal.propTypes = {
    setOpen: PropTypes.func.isRequired,
    setInterpreter: PropTypes.func,
};

export default InviteInterpreterModal;
