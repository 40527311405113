import './Notes.scss';
import { Tooltip } from 'library';
import PropTypes from 'prop-types';

const Notes = ({ text }) => {
    if (!text) {
        return null;
    }
    return (
        <Tooltip title={text} className="cell-notes">
            {text}
        </Tooltip>
    );
};

Notes.propTypes = {
    text: PropTypes.string
};

export { Notes };
