import platformClient from 'purecloud-platform-client-v2/src/purecloud-platform-client-v2/index';

const usersApi = new platformClient.UsersApi();
const notificationsApi = new platformClient.NotificationsApi();
const externalConctactsApi = new platformClient.ExternalContactsApi();
const conversationsApi = new platformClient.ConversationsApi();
const architectApi = new platformClient.ArchitectApi();
const analyticsApi = new platformClient.AnalyticsApi();
const routingApi = new platformClient.RoutingApi();

const apiClient = platformClient.ApiClient.instance;
        
export {
    usersApi,
    notificationsApi,
    externalConctactsApi,
    conversationsApi,
    architectApi,
    analyticsApi,
    routingApi,
    apiClient
};
