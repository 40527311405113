import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, Input } from 'library';

const FilterCheckboxes = ({
    selectedKeys = [], 
    setSelectedKeys,
    confirm, 
    filters, clearFilters
}) => {
    const [value, setValue] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setValue(selectedKeys);
    }, [selectedKeys]);

    const handleClearFilters = () => {
        setValue();
        clearFilters();
        confirm();
    };

    const handleSearch = () => {
        if (value) {
            setSelectedKeys(value);
            confirm();
        } else {
            clearFilters();
        }
    };
    
    const filteredFilters = filters
        .filter(item => item.label.toLowerCase().includes(searchValue.toLowerCase()));
        
    return (
        <div>
            {filters.length >= 10 && (
                <Input.Search 
                    autoFocus
                    onChange={e => setSearchValue(e.target.value)}
                />
            )}
            <Checkbox.Group
                onChange={setValue}
                value={value}
                className="checkbox-group-vertical"
            >
                {filteredFilters.map(i => (
                    <Checkbox
                        key={i.value}
                        value={i.value}
                    >
                        {i.display || i.label}
                    </Checkbox>
                ))}
            </Checkbox.Group>
            <div className="lx-table-filter-dropdown-btns">
                <Button
                    type="link"
                    size="small"
                    onClick={handleClearFilters}
                    className="lx-table-filter-dropdown-link clear"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={handleSearch}
                    className="lx-table-filter-dropdown-link confirm"
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

FilterCheckboxes.propTypes = {
    selectedKeys: PropTypes.array, 
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired, 
    filters: PropTypes.array.isRequired, 
    clearFilters: PropTypes.func.isRequired,
};

export default FilterCheckboxes;
