const transformContactsIn = (facility) => {
    if (!facility.contacts?.length) {
        return null;
    }
    
    return facility.contacts.map(contact => ({
        id: `${facility.id}-${contact.id}`,
        facilityProviderContactId: contact.id,
        facilityProviderContactName: `${`${contact.firstName} ${contact.lastName}`}, ${contact.title}`,
        facilityId: facility.id,
        name: facility.name,
        type: facility.type,
    }));
};

export default transformContactsIn;
