import './LookupDropdown.scss';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { BaseTable, Button, Form } from 'library';
import { useService, useDebounce } from 'hooks';
import { showError } from 'utils';
import InputFilter from './InputFilter';

const LookupDropdown = ({
    onOk, onCancel, title,
    service,
    source: mainSource,
    columns,
    setOpen,
    transform,
    defaultFilters = {},
    className,
    minFilters = 1,
    isDisabled = () => false,
}) => {
    const [form] = Form.useForm();
    const [selected, setSelected] = useState();
    const [selectedKey, setSelectedKey] = useState();
    const [applying, setApplying] = useState(false);
    const [thisColumns, setThisColumns] = useState([]);
    const [debouncedFilters, setdDebouncedFilters] = useDebounce({}, 1500);
    const lastFilters = useRef({});

    const transformFn = useCallback((_data) => {
        let res = _data.map((item, idx) => {
            const resItem = {
                $key: `${item.source}-${idx}`,
                fields: {},
                thirdParty: {
                    source: mainSource || item.source,
                    ...item
                }
            };
            columns.forEach(({ source, target, targetFn }) => {
                let value = item[source];
                value = Object.isString(value) ? value.trim() : value;
                if (target) {
                    const val = targetFn ? targetFn(value, item) : value;
                    if (val) {
                        resItem.fields[target] = {
                            value: val,
                            unknown: false,
                            refused: false,
                            estimated: false
                        };
                    } else {
                        resItem.fields[target] = {
                            value: val
                        };
                    }
                }
                resItem[target || source] = value;
            });
            return resItem;
        });
        if (transform) {
            res = transform(res);
        }
        return res;
    }, [mainSource, columns, transform]);

    const [loadData, { data, loading }] = useService(service, [], transformFn);

    const onFinish = useCallback((values) => {
        const filters = Object.removeEmptyProps(values);
        if (!Object.isEmpty(filters) && Object.keys(filters).length >= minFilters) {
            if (!Object.areShallowEquals(filters, lastFilters.current)) {
                lastFilters.current = filters;
                loadData(filters);
            }
        }
    }, [loadData, minFilters]);

    const onRowChange = (item) => {
        const disabled = isDisabled(item);
        if (!disabled) {
            setSelected(item);
            setSelectedKey(item?.$key);
        }
    };

    const onApply = () => {
        setApplying(true);
        onOk(selected)
            .then(() => setOpen(false))
            .catch(showError)
            .finally(() => setApplying(false));
    };

    useEffect(() => {
        const _buildColumns = (cols) => {
            const res = cols.map((column) => {
                const {
                    options, source, target,
                    required, label, filter, filterType, ...rest
                } = column;
                return {
                    dataIndex: target || source,
                    key: target,
                    width: column.width || 100,
                    ...rest,
                    title: filter ? (
                        <InputFilter
                            name={filter}
                            label={label}
                            onChange={() => {
                                setSelected();
                                setSelectedKey([]);
                            }}
                            required={required}
                            options={options}
                            filterType={filterType}
                        />
                    ) : label,
                };
            });
            setThisColumns(res);
        };
        _buildColumns(columns);
    }, [columns]);

    useEffect(() => {
        onFinish(debouncedFilters);
    }, [debouncedFilters, onFinish]);

    useEffect(() => {
        form.resetFields();
        setTimeout(() => {
            form.submit();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters]);

    return (
        <Form
            form={form}
            hideRequiredMark
            onFinish={onFinish}
            initialValues={defaultFilters}
            onValuesChange={(_, all) => setdDebouncedFilters(all)}
        >
            <BaseTable
                className={`lookup-dropdown-table white ${className}`}
                rowKey="$key"
                pagination={false}
                title={() => title}
                columns={thisColumns}
                dataSource={data || []}
                rowSelection={{
                    type: 'radio',
                    onChange: (_, items) => onRowChange(items[0]),
                    selectedRowKeys: selectedKey ? [selectedKey] : [],
                }}
                rowClassName={item => (isDisabled(item) ? 'disabled' : '')}
                onRow={item => ({
                    onClick: () => onRowChange(item),
                })}
                size="small"
                scroll={{ x: thisColumns.sum('width'), y: 300 }}
                loading={loading}
                // eslint-disable-next-line react/no-unstable-nested-components
                footer={() => (
                    <>
                        <span>Total: <b>{data.length === 500 ? '500+ ' : data.length}</b></span>
                        <Button
                            type="link"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={onApply}
                            disabled={!selected}
                            loading={applying}
                        >
                            Apply
                        </Button>
                    </>
                )}
            />
        </Form>
    );
};

LookupDropdown.propTypes = {
    onOk: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    service: PropTypes.func.isRequired,
    transform: PropTypes.func,
    source: PropTypes.string.isRequired,
    columns: PropTypes.array,
    defaultFilters: PropTypes.object,
    className: PropTypes.string,
    minFilters: PropTypes.number,
    isDisabled: PropTypes.func,
};

export { LookupDropdown };
