import { IconNoTriage } from 'assets';
import { SubHeader } from 'layout';

const NoTriageTitle = () => (
    <SubHeader 
        prefix={<img src={IconNoTriage} alt="Set Treatment Facility" />} 
        title="Set Treatment Facility"
    />
);

export default NoTriageTitle;
