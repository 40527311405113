import './FormItemDirective.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { getField } from 'config';

const Directive = ({
    type = 'TEXT',
    required = false, 
    onChange, value, 
    disabled, className,
    ...rest
}) => {
    const ElementBase = getField({ type, isDirective: true, ...rest });

    const onBeforeChange = (e) => {
        let elementValue = e;
        if (e?.bubbles) {
            elementValue = e.target.value;
        }
        onChange(elementValue);
    };

    const Element = React.cloneElement(ElementBase, {
        value,
        onChange: onBeforeChange,
        required,
        placeholder: required ? 'Required' : '',
        disabled,
        className: `${className} ${!!value ? 'has-value' : ''}`
    });

    return Element;
};

Directive.propTypes = {
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.array,
    required: PropTypes.bool,
};

export default Directive;
