import { INCIDENT_TYPE_WC } from 'config';
import api from '../api';
import transformList from './transform.list';

class EnterpriseQuestionsService {
    static async list({ id, episodeTypeId = INCIDENT_TYPE_WC }) {
        const categories = await EnterpriseQuestionsService.categories({ episodeTypeId });
        
        return api.get(`/api/v2/locations/${id}/questions`, { episodeTypeId })
            .then(data => transformList(id, data, categories));
    }

    static async categories(filters = {}) {
        const { data } = await api.get('/api/v2/question-categories', filters);
        return data;
    }
}

export default EnterpriseQuestionsService;
