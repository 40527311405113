import './TextArea.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const { TextArea: TextAreaBase } = Input;

const TextArea = ({ 
    value, 
    onChange = () => { }, 
    autoSize = { minRows: 4 },
    onContextMenu, 
    ...rest 
}) => {
    const ref = React.useRef();

    const _onContextMenu = (event) => {
        onContextMenu(event, ref.current.resizableTextArea.textArea);
    };

    return (
        <TextAreaBase
            ref={ref}
            value={value}
            onChange={e => onChange(e.target.value)}
            onContextMenu={onContextMenu && _onContextMenu}
            allowClear
            autoSize={autoSize}
            spellCheck
            {...rest}
        />
    );
};

TextArea.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    autoSize: PropTypes.object,
    onContextMenu: PropTypes.func
};

export { TextArea };
