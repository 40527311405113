import './NewFacility.scss';
import React, { useContext, useState } from 'react';
import { Form, Button } from 'library';
import { useSelector } from 'react-redux';
import { Rules } from 'utils';
import formConfig from './config';
import FacilityContext from '../../context';

const NewFacilitiyTab = () => {
    const form = Form.useFormInstance();
    const { onFacilityChange } = useContext(FacilityContext);
    const [dirty, setDirty] = useState(false);
    const { locationId } = useSelector(state => state.incident);
    
    const onChange = () => {
        setDirty(true);
    };

    const onSelect = () => {
        const names = formConfig.map(item => (['newFacility', item.name]));
        form.validateFields(names)
            .then((values) => {
                setDirty(false);
                onFacilityChange({ ...values.newFacility, locationId });
            });
    };

    return (
        <>
            <div className="tab-content tab-content-new-facility">
                <div>
                    {formConfig.map(item => (
                        <Form.Item
                            key={item.name}
                            className={`${item.required ? 'required' : ''}`}
                            rules={item.required ? [Rules.required] : []}
                            label={item.label}
                            name={['newFacility', item.name]}
                        >
                            {React.createElement(item.component, { 
                                onChange,
                                onBlur: onSelect,
                                placeholder: item.required ? 'required' : '',
                                options: item.options
                            })}
                        </Form.Item>
                    ))}
                </div>
            </div>
            <div className="tab-footer-new-facility">
                <Button
                    type="primary"
                    onClick={onSelect}
                    disabled={!dirty}
                >
                    Select
                </Button>
            </div>
        </>
    );
};

export default NewFacilitiyTab;
