import './Services.scss';
import PropTypes from 'prop-types';
import { HolderOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'library';

const FacilityServices = ({ item }) => {
    const availableServices = item.services?.filter(it => it.isActive || it.isUnknown);

    if (!availableServices?.length) {
        return null;
    }

    const title = availableServices.map(service => (
        <div key={service.id}>
            <span>{service.isUnknown && <QuestionCircleOutlined />}</span>
            <span>{service.name}</span>
        </div>
    ));

    return (
        <Tooltip
            overlayClassName="services-info-popover"
            placement="top"
            title={title}
        >
            <HolderOutlined
                style={{ cursor: 'pointer' }}
            />
        </Tooltip>
    );
};

FacilityServices.propTypes = {
    item: PropTypes.object.isRequired
};

export { FacilityServices };
