import './filter.date.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { formats } from 'utils';
import { DatePicker } from 'library';

const { RangePicker } = DatePicker;

const FilterDate = ({
    selectedKeys = [], 
    setSelectedKeys,
    confirm, clearFilters, 
    pattern, visible
}) => {
    const [value, setValue] = useState(false);
    const ref = React.useRef();

    useEffect(() => {
        if (ref.current) {
            setTimeout(() => {
                ref.current.focus();
            });
        }
    }, [visible]);
    
    useEffect(() => {
        let defaultValues;
        if (selectedKeys?.length === 3) {
            defaultValues = [selectedKeys[1], selectedKeys[2]];
        }
        setValue(defaultValues);
    }, [selectedKeys]);

    const handleClearFilters = () => {
        setValue();
        clearFilters();
        confirm();
    };

    const handleSearch = () => {
        if (value) {
            setSelectedKeys([pattern, ...value]);
            confirm();
        } else {
            clearFilters();
        }
    };

    return (
        <div className="table-filter-dropdown-dates">
            <RangePicker
                format={formats.date.in}
                ref={ref}
                value={value}
                onChange={setValue}
            />
            <div className="lx-table-filter-dropdown-btns">
                <Button
                    type="link"
                    size="small"
                    onClick={handleClearFilters}
                    className="lx-table-filter-dropdown-link clear"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={handleSearch}
                    className="lx-table-filter-dropdown-link confirm"
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

FilterDate.propTypes = {
    selectedKeys: PropTypes.array,
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    pattern: PropTypes.oneOf(['BETWEENDATE', 'BETWEENDATE_EXACT']).isRequired,
    visible: PropTypes.bool.isRequired,
};

export default FilterDate;
