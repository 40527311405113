import React from 'react';
import { useSelector } from 'react-redux';
import { InputSsn } from 'library';
import { SNN_SHOW_LAST_FOUR } from 'config/location';

const FieldSsn = (props) => {
    const { ssnDigitsOnIntake } = useSelector(state => state.location);
    const last4Only = ssnDigitsOnIntake === SNN_SHOW_LAST_FOUR;

    return (
        <InputSsn
            last4Only={last4Only}
            {...props}
        />
    );
};

export { FieldSsn };
