import Icon, { QuestionCircleFilled } from '@ant-design/icons';
import { IconER } from 'assets';

export const FACILITY_TYPE = {
    UNKNOWN: null,
    DENTAL_OFFICE: '1',
    ER: '2',
    NON_ER: '3',
    TYPE_URGENT_CARE: '4'
};

export const FACILITY_SOURCE = {
    INTERNA: 0,
    TALISPOINT: 1, 
    BROADSPIRE: 2
};

export const FacilityTypeIcon = {
    [FACILITY_TYPE.NON_ER]: null,
    [FACILITY_TYPE.ER]: <Icon component={IconER} />,
    [FACILITY_TYPE.UNKNOWN]: <QuestionCircleFilled />,
};

export const FacilityTypeOptions = [
    {
        value: FACILITY_TYPE.ER,
        label: 'ER'
    },
    {
        value: FACILITY_TYPE.NON_ER,
        label: 'Non ER'
    }
];
