export const InjuryOptions = [
    {
        value: 'EMPLOYEE_NOT_PRESENT',
        label: 'Employee Not Present',
        description: 'Employee was not present. Advised to call us when employee is available.'
    },
    {
        value: 'DECLINED_TRIAGE',
        label: 'Declined Triage',
        description: 'Does not want to speak to a triage person or seek medical treatment at this time. Advised to call us back if their status changes.'
    },
    {
        value: 'FACILITY_REFERRAL_ONLY',
        label: 'Facility Referral Only',
        description: 'Employee requested referral to treatment facility.'
    },
    {
        value: 'PREVIOUSLY_TREATED',
        label: 'Previously Treated',
        description: 'Treated Prior to contacting Injury Hotline.'
    },
    {
        value: 'INCOMPLETE',
        label: 'Incomplete',
        description: 'Requires additional information to complete.Save and Send.'
    },
    {
        value: 'HANGUP_LOST_CALL',
        label: 'Hangup/Lost Call',
        description: 'Caller/Employee hung up or call dropped.Save and Send.'
    },
    {
        value: 'EMPLOYEE_OTHER_RETURNED_CALL',
        label: 'Employee/Other Returned Call',
        description: 'Called the hotline with additional information.Save and Send.'
    },
    {
        value: 'INTERNAL_AUDIT_REPORT_REVIEW',
        label: 'Internal Audit/Report Review',
        description: 'Document report only.'
    },
    {
        value: 'EMPLOYER_FOLLOW-UP',
        label: 'Employer Follow-up',
        description: 'Follow-up call by RN requested by employer.'
    },
    {
        value: 'INTAKE_ONLY_CUSTOMER',
        label: 'Intake Only Customer',
        description: 'Customer is report only and does not require nurse triage.'
    }
];
