import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Select } from 'library';
import { useSelector, useDispatch } from 'react-redux';
import { transferToRN } from 'store/call';
import { StateAttributes } from 'config';
import { Rules } from 'utils';

const TransferToRNModal = ({ setOpen }) => {
    const [form] = Form.useForm();
    const { state: locationState } = useSelector(state => state.location);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (locationState) {
            form.resetFields();
        }
    }, [form, locationState]);

    const onFinish = ({ SelectedState }) => {
        setLoading(true);
        dispatch(transferToRN(SelectedState))
            .unwrap()
            .then(() => setOpen(false))
            .finally(() => setLoading(false));
    };

    const initialValues = {
        SelectedState: locationState
            ? StateAttributes.find(name => name.startsWith(locationState))
            : undefined
    };

    return (
        <Modal
            className="info modal-choose-to-continue"
            title="Transfer To a Registered Nurse"
            open
            onOk={form.submit}
            confirmLoading={loading}
            onCancel={() => setOpen(false)}
            cancelButtonProps={{ type: 'text' }}
            closable={false}
            centered
        >
            <div className="content">
                <Form
                    form={form}
                    layout="vertical"
                    hideRequiredMark
                    onFinish={onFinish}
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="SelectedState"
                        rules={[Rules.required]}
                        label="State Certification Required"
                    >
                        <Select
                            options={StateAttributes.map(value => ({
                                value,
                                label: value
                            }))}
                            allowClear
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

TransferToRNModal.propTypes = {
    setOpen: PropTypes.func.isRequired
};

export default TransferToRNModal;
