import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'library';
import { scrollTop } from 'utils';
import { ButtonValidateIncident } from 'components/buttons';
import IncidentContext from 'routes/Incident/context';

const NextIntakeButton = ({ disabled, nextId }) => {
    const navigate = useNavigate();
    const { update } = useContext(IncidentContext);
    const incident = useSelector(state => state.incident);
    const form = Form.useFormInstance();
    const [sending, setSending] = useState(false);

    const onContinueClick = () => {
        setSending(true);
        form.validateFields()
            .then(values => update({
                id: incident.id,
                ...values,
            }))
            .then(() => {
                scrollTop();
                navigate(`/incidents/${nextId}/intake`); 
            })
            .catch(Form.onFinishFailed)
            .finally(() => setSending(false));
    };
    
    return (
        <ButtonValidateIncident
            onClick={onContinueClick}
            disabled={disabled}
            loading={sending}
            title="Proceed to the next intake"
        >
            Continue
        </ButtonValidateIncident>
    );
};

NextIntakeButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    nextId: PropTypes.string.isRequired
};

export default NextIntakeButton;
