import { formatDate, formatSsn } from 'utils';

export const IncidentProps = [
    {
        key: 'incidentId',
        label: 'Incident Id',
        render: item => <u> {item.claimReferenceNumber || item.id} </u>
    },
    {
        key: 'createdDate',
        label: 'Date Created',
        render: item => formatDate(item.createdDate)
    },
    {
        key: 'incidentTypeId',
        label: 'Incident Type',
        render: item => item.$incidentType?.title
    },
    {
        key: 'injuryDate',
        label: 'Incident Date',
        render: item => formatDate(item.fields?.injury_date?.value)
    },

    {
        key: 'employer_name',
        label: 'Employer',
        render: item => item.fields?.employer_name?.value
    },
    {
        key: 'claimantFirstLastName',
        label: 'Claimant',
        render: item => `${item.fields?.claimant_first_name?.value} ${item.fields?.claimant_last_name?.value}`
    },
    {
        key: 'claimantSsn',
        label: 'SSN',
        render: item => formatSsn(item.fields?.claimant_ssn?.value)
    },
    {
        key: 'claimantBirthDate',
        label: 'Date of Birth',
        render: item => formatDate(item.fields?.claimant_birth_date?.value)
    },
];
