import './IncidentNoTriageSummary.scss';
import IncidentSummary from 'components/IncidentSummary';
import { EsNotesService } from 'services';
import { Grid, SubHeader } from 'layout';
import { IconNoTriage } from 'assets';
import FormSummary from './Form';

const IncidentNoTriageSummary = () => (
    <>
        <SubHeader
            prefix={<img src={IconNoTriage} alt="Incident Summary" />} 
            title="Incident Summary"
        />
        <Grid className="grid-no-triage-summary">
            <FormSummary />
            <IncidentSummary
                noteType={EsNotesService.ALL_TYPE}
            />
        </Grid>
    </>
);

export default IncidentNoTriageSummary;
