export function debounce(func, interval = 200) {
    let timeout;
    return function d1(...args) {
        const context = this;
        const later = function d2() {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, interval);
    };
}
