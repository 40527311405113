import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Test from 'components/MockedCall';
import { Button } from 'library';
import { formatPhone } from 'utils';
import { ReloadOutlined } from '@ant-design/icons';
import { SubHeader } from 'layout';
import { getActiveConversation } from 'store/call';

import {
    IconCallWaiting,
    IconCallIncoming,
    IconCallConnected,
    IconCallDisconnected
} from 'assets';

const images = {
    waiting: IconCallWaiting,
    alerting: IconCallIncoming,
    connected: IconCallConnected,
    disconnected: IconCallDisconnected,
    terminated: IconCallWaiting
};

const CallerIdTitle = () => {
    const { fromPhone, isConnected } = useSelector(state => state.call);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onClick = () => {
        setLoading(true);
        dispatch(getActiveConversation())
            .unwrap()
            .finally(() => setLoading(false));
    };

    const title = fromPhone ? formatPhone(fromPhone) : 'Waiting for a call';
    return (
        <SubHeader
            prefix={(
                <img src={images[isConnected ? 'connected' : 'waiting']} alt={title} />
            )}
            title={title}
            suffix={(
                <Button
                    shape="circle"
                    title="Load Active Conversation Data"
                    icon={<ReloadOutlined />}
                    type="text"
                    onClick={onClick}
                    loading={loading}
                />
              )}
        >
            <Test />
        </SubHeader>
    );
};

export default CallerIdTitle;
