/* eslint-disable max-len */
import { formatSsn, formatDate, } from 'utils';
import { USStateOptions } from 'config';
import { EsLocationsService } from 'services';

export default {
    source: 'EmployeeBridge',
    sourceType: 'contact',
    title: 'Employbridge',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            target: 'claimant_last_name',
            source: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            target: 'claimant_first_name',
            source: 'firstName',
            filter: 'firstName',
            width: 120,
        },
        {
            label: 'SSN',
            source: 'ssn',
            filter: 'ssn',
            target: 'claimant_ssn',
            filterType: 'ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            source: 'dob',
            filter: 'dob',
            target: 'claimant_birth_date',
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            target: 'claimant_employee_id',
            source: 'employeeId',
            filter: 'employeeId',
            width: 80
        },
        {
            label: 'Hire Date',
            source: 'hireDate',
            filter: 'hireDate',
            target: 'employment_hired_date',
            render: formatDate,
            width: 90
        },
        {
            label: 'Gender',
            source: 'gender',
            target: 'claimant_gender_code',
            targetFn: value => ({ M: '1', F: '2' }[value] || '3'),
            width: 60
        },
        {
            label: 'Address',
            source: 'address1',
            target: 'claimant_address',
            width: 120
        },
        {
            label: 'City',
            source: 'city',
            target: 'claimant_city',
            width: 100
        },
        {
            label: 'State',
            target: 'claimant_state',
            source: 'state',
            options: USStateOptions,
            width: 90
        },
        {
            label: 'Zip',
            source: 'zip',
            target: 'claimant_zip',
            width: 70
        },
    ],
    onSelect: async (item) => {
        const locationNumber = item.thirdParty?.branchCode;

        if (locationNumber) {
            const locations = await EsLocationsService.list({
                locationNumber,
                reportableLocation: 1,
                title: 'Employbridge',
                sortBy: 'title',
                sortDirection: 'asc',
                page: 1,
                perPage: 1
            });
            if (locations.length) {
                return {
                    ...item,
                    locationId: locations[0].id
                };
            }
        }

        return item;
    }
};
